<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img style="max-height: 75px" v-lazy="dados_concurso.logo_cliente">
                  </a>
                </div>
              </div>
              <div class="col-lg-4 mt-5 order-lg-3 text-lg-right align-self-lg-center">
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">22</span>-->
                  <!--                                        <span class="description">Número de inscrição</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">10</span>-->
                  <!--                                        <span class="description">Nome</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">89</span>-->
                  <!--                                        <span class="description">CPF</span>-->
                  <!--                                    </div>-->
                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <div class="h6 font-weight-300" style="width: 100%"><i class="ni location_pin mr-2"></i>
                {{ dados_concurso.nome_cliente }}
              </div>
              <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>{{ dados_concurso.nome_concurso }}
              </div>
              <h3>Impugnação do Edital</h3>

            </div>

            <div class="row mt-5">
              <h5 class=" col-md-2">CPF</h5>
              <div class="col-md-6">
                <base-input :valid="valida_cpf()" @change="valida_cpf()" v-mask="'###.###.###-##'"
                            v-model="impugnacao.cpf"></base-input>
              </div>
            </div>

            <div class="row">
              <h5 class="col-md-2">Nome</h5>
              <div class="col-md-6">
                <base-input v-model="impugnacao.nome"></base-input>
              </div>
            </div>

            <div class="row">
              <h5 class="col-md-2">Cargo</h5>
              <div class="col-md-6">
                <base-dropdown>
                  <base-button slot="title" type="secondary" class="dropdown-toggle">
                    {{ cargo_selecionado }}
                  </base-button>
                  <div :key="item" v-for="item of cargos">
                    <li @click="set_cargo(item)" class="dropdown-item">
                      {{ item }}
                    </li>
                  </div>
                </base-dropdown>
              </div>
            </div>

            <div class="row mt-3">
              <h5 class="col-md-2">Tipo de Impugnação</h5>
              <div class="col-md-6">
                <base-dropdown>
                  <base-button slot="title" type="secondary" class="dropdown-toggle">
                    {{ tipo_impugnacao }}
                  </base-button>
                  <div :key="item" v-for="item of tipos_de_impugnacao">
                    <li @click="set_tipo_impugnacao(item)" class="dropdown-item">
                      {{ item }}
                    </li>
                  </div>
                </base-dropdown>
              </div>
            </div>

            <div class="row">
              <h5 class="col-md-2 mt-5">Motivo</h5>
              <div class="col-md-6">
                <textarea maxlength="5000" class="form-control mt-4" rows="3" v-model="impugnacao.motivo"></textarea>
                Limite de caracteres {{ impugnacao.motivo.length }}/5000

              </div>
            </div>

            <!--            <div class="row mt-4 justify-content-center">-->
            <!--              <vue-hcaptcha v-on:verify="valida_captcha" sitekey="f7990c82-4cee-42ee-abd3-5ab1bad7a272"></vue-hcaptcha>-->
            <!--            </div>-->

            <div class="mt-1 py-4 border-top text-center">
              <div class="btn_enviar row justify-content-center">
                <div class="col-lg-9">
                  <div class="btnConfirmacao">
                    <base-checkbox class="mb-3" v-model="btnConfirmacao">
                      <b>Declaro que as informações acima estão corretas e são verdadeiras.</b>
                    </base-checkbox>
                  </div>
                  <base-button @click="envia_impuganacao()" :disabled="!btnConfirmacao" native-type="submit"
                               type="primary" class="my-4">
                    Enviar
                    Impugnação
                  </base-button>
                  <!--                  <base-button native-type="submit" type="danger" class="my-4">Sair</base-button>-->
                  <modal :show.sync="modal1">
                    <h6 slot="header" class="modal-title" id="modal-title-default">Erro ao protocolar envio de
                      Impugnação do Edital</h6>


                    <h5 v-if="!valida_cpf()">O CPF é inválido</h5>
                    <b v-if="!valida_cpf()">Digite um CPF válido para continuar</b>

                    <br>

                    <h5 v-if="!validacao_cargo">Selecione um cargo</h5>
                    <b v-if="!validacao_cargo">É necessário selecionar um cargo para continuar</b>

                    <br>

                    <h5 v-if="!validacao_tipo_impugnacao">Selecione um tipo de impugnação</h5>
                    <b v-if="!validacao_tipo_impugnacao">É necessário selecionar um tipo de impugnação  para continuar</b>

                    <br>

                    <h5 v-if="!validacao_captcha">Escreva um Motivo</h5>
                    <b v-if="!validacao_captcha">É necessário escrever um motivo para continuar</b>

                    <template slot="footer">
                      <base-button type="link" class="ml-auto" @click="modal1 = false">Voltar
                      </base-button>
                    </template>
                  </modal>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from '../components/Modal';
import axios from "axios";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import {cpf} from 'cpf-cnpj-validator';
import BaseDropdown from "@/components/BaseDropdown";


export default {
  data: function () {
    return {
      concurso: '',
      cargo_selecionado: 'Selecione o cargo',
      tipo_impugnacao: 'Selecione o tipo',
      validacao_cargo: false,
      validacao_tipo_impugnacao: false,
      validacao_captcha: false,
      validacao_motivo: false,
      impugnacao: {cpf: '', nome: '', cargo: '', motivo: '',tipo_impugnacao:''},
      dados_concurso: {},
      file: "",
      protocolo_gerado: '',
      modal1: false,
      btnConfirmacao: false,
      cargos: ['Analista Judiciário Administrador', 'Analista Judiciário Analista de Tecnologia da Informação', 'Analista Judiciário Analista Judiciário', 'Analista Judiciário Assistente Social', 'Analista Judiciário Bibliotecário', 'Analista Judiciário Contador', 'Analista Judiciário Enfermeiro', 'Analista Judiciário Engenheiro Civil', 'Analista Judiciário Engenheiro Eletricista', 'Analista Judiciário Engenheiro Mecânico', 'Analista Judiciário Médico', 'Analista Judiciário Psicólogo', 'Analista Judiciário Revisor Judiciário', 'Oficial Judiciário Assistente Técnico de Controle Financeiro', 'Oficial Judiciário Oficial de Justiça'],
      tipos_de_impugnacao: ['Item do Edital', 'Conteúdo Programático', 'Outros']
    }
  },
  components: {
    BaseDropdown,
    Modal: Modal,
    VueHcaptcha
  },
  beforeMount() {
    (async () => {

      //Popula dados do concurso;
      this.concurso = '2216' //TODO Pegar da URL
      localStorage.setItem('tipo_pagina', 'impugnacao');
      localStorage.setItem('concurso', this.concurso);


      try {
        let response = await axios.get('/form/info_concurso_impugnacao', {params: {concurso: this.concurso}})
        this.dados_concurso = response.data;
        // localStorage.setItem('protocolo', this.protocolo_gerado);
      } catch (error) {
        // alert(`Um erro ocorreu, tente novamente.`)
        console.log(`Um erro ocorreu`, error.message);
        // await this.$router.push('/login/' + concurso+'?login=isencao');
      }

    })();

  },
  methods: {
    set_cargo(cargo) {
      console.log(this.cargo_selecionado);
      this.cargo_selecionado = cargo;
      this.impugnacao = {...this.impugnacao, cargo: cargo}

      console.log(`Cargo`, this.impugnacao.cargo)
      return;
    },
    set_tipo_impugnacao(tipo) {
      this.tipo_impugnacao = tipo;
      this.impugnacao = {...this.impugnacao, tipo_impugnacao: tipo}
      return;
    },
    valida_captcha(token, ekey) {
      this.validacao_captcha = true;
    },
    valida_cpf() {
      if (!this.impugnacao.cpf)
        return undefined;

      if (!cpf.isValid(this.impugnacao.cpf)) {
        this.validacao_cpf = false;
        return false;
      } else {
        this.validacao_cpf = true;
        return true;
      }

    },
    async envia_impuganacao() {
      // alert(`Prazo encerrado`);
      // return;
      console.log(this.impugnacao)
      this.impugnacao.cargo.length === 0 ? this.validacao_cargo = false : this.validacao_cargo = true;
      this.impugnacao.motivo.length === 0 ? this.validacao_motivo = false : this.validacao_motivo = true;
      this.impugnacao.tipo_impugnacao.length === 0 ? this.validacao_tipo_impugnacao = false : this.validacao_tipo_impugnacao = true;
      let data = new Date();



      let data_fim = new Date('2023-08-22 02:59:00Z')

      if (data > data_fim) {
        alert(`Prazo encerrado`);
        return;
      }

      if (!this.valida_cpf() || !this.validacao_motivo || !this.validacao_cargo || !this.validacao_tipo_impugnacao) {
        this.modal1 = true;
        return;
      }

      console.log('Estou enviando.:', this.impugnacao)

      let response = await axios.post('/form/impugnacao', {
        concurso: this.concurso,
        cpf: this.impugnacao.cpf,
        nome: this.impugnacao.nome,
        cargo: this.impugnacao.cargo,
        motivo: this.impugnacao.motivo,
        tipo_impugnacao: this.impugnacao.tipo_impugnacao,
      })
      console.log('Estou recebendo:', response.data);
      localStorage.setItem('impugnacao', JSON.stringify(response.data));


      await this.$router.push('/protocoloimpugnacao');
    }

  }
}
;
</script>
<style scoped>
.file-name {
  margin: 5px;
}

.profile-page {
  margin-bottom: 300em;
}
</style>
