<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img style="max-height: 75px" v-lazy="dados_concurso.logo_cliente">
                  </a>
                </div>
              </div>
              <div class="col-lg-4 mt-4 order-lg-3 text-lg-right align-self-lg-center">
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">

                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <h3>Aprovador
              </h3>
              <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ dados_concurso.nome_cliente }}
              </div>
              <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>{{ dados_concurso.nome_concurso }}
              </div>
            </div>
            <div class="mt-5 py-3 border-top text-center">
              <div class="row mb-1  justify-content-center">
                <h5>Filtros</h5>
              </div>
              <div class="row justify-content-center">
                <div class="col-md-3">
                  <h6>Status do Título</h6>
                </div>
                <div class="col-md-3">
                  <base-dropdown>
                    <base-button slot="title" type="secondary" class="dropdown-toggle">
                      {{ !filtro_status_titulo ? 'Todos' : filtro_status_titulo }}
                    </base-button>
                    <li class="dropdown-item" @click="filtro_status_titulo=null">Todos</li>
                    <li class="dropdown-item" @click="filtro_status_titulo='pendente'">Pendente</li>
                    <li class="dropdown-item" @click="filtro_status_titulo='avaliado'">Avaliado</li>
                  </base-dropdown>
                </div>

              </div>
              <div class="row">
                <div class="mt-1 py-3 text-center col-md-12">
                  <h6>Busca de Candidatos</h6>
                  <p>Utilize vírgula para separar múltiplos candidatos</p>
                  <div class="col-md-12">
                    <base-input v-model="busca_candidato"
                                placeholder="Número(s) de inscrição e/ou nome do(s) candidato(s)"></base-input>
                  </div>
                </div>
              </div>

              <div class="row justify-content-center">
                <div class="col-md-3 ">
                  <base-button @click="seta_filtro()">
                    Aplicar Filtros
                  </base-button>
                </div>
              </div>

            </div>

            <div class="mt-5 py-3 border-top text-center">
              <h5>Lista de Envios</h5>
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th scope="col">Inscrição</th>
                      <th scope="col">Nome</th>
                      <th scope="col">Protocolo</th>
                      <!--                      <th scope="col">Título</th>-->
                      <th scope="col">Situação</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-if="dados_concurso.concurso.indexOf('redacao')>=0" v-for="(item) in lista_envios"
                        :key="item.protocolo+item.tipo_arquivo">
                      <th scope="row">{{ item.num_cand.replace('-', '') * 13 }}</th>
                      <!--                      <th scope="row">{{ item.num_cand }}</th>-->
                      <td>Candidato</td>
                      <!--                      <td>{{ item.nome }}</td>-->
                      <td>redacao</td>
                      <!--                      <td>{{ item.tipo_arquivo.toUpperCase() + ` (${item.pontuacao_pedida})` }}</td>-->
                      <td>
                        <base-button @click="visualiza(item)"
                                     v-bind:type="item.status === 'PENDENTE' ? 'secondary' : 'success'">
                          {{ item.status === 'PENDENTE' ? 'pendente' : 'avaliado' }}
                        </base-button>
                      </td>
                    </tr>

                    <tr v-if="dados_concurso.concurso.indexOf('redacao')<0" v-for="(item) in lista_envios"
                        :key="item.protocolo+item.tipo_arquivo">
                      <th scope="row">{{ item.num_cand }}</th>
                      <td>{{ item.nome }}</td>
                      <td>{{ item.protocolo }}</td>
                      <!--                      <td>{{ item.tipo_arquivo + ` (${item.pontuacao_pedida})` }}</td>-->
                      <td>
                        <base-button @click="visualiza(item)"
                                     v-bind:type="item.status === 'PENDENTE' ? 'secondary' : 'success'">
                          {{ item.status === 'PENDENTE' ? 'pendente' : 'avaliado' }}
                        </base-button>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <base-pagination :page-count="qtd_paginas" align="center" v-model="pagination"></base-pagination>
        </card>
      </div>
    </section>

    <loading :active="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"/>

  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../components/Modal';
import axios from "axios";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Vue from 'vue'
import base_dropdown from '../components/BaseDropdown'
import base_pagination from '../components/BasePagination'

Vue.use(VueViewer)

export default {
  data: function () {
    return {
      pagination: 1,
      qtd_paginas: 5,
      filtro_status_titulo: null,
      candidato_analisado: {},
      modal_info: {},
      dados_candidato: {},
      dados_concurso: {},
      file: "",
      btnConfirmacao: true,
      images: [],
      lista_envios: [],
      isLoading: false,
      fullPage: true,

      busca_candidato: '',
    }
  },
  components: {
    Loading,
    vueDropzone: vue2Dropzone,
    Modal: Modal,
    'base-dropdown': base_dropdown,
    'base-pagination': base_pagination,

  }, beforeMount() {
    (async () => {
      //Popula dados do concurso;
      let token = localStorage.getItem('token');
      let concurso = localStorage.getItem('concurso');
      try {
        let response = await axios.get('/form/info_concurso', {params: {token, concurso}})
        this.dados_concurso = response.data;

        response = await axios.get('/aprovador/pega_candidatos_com_titulos', {
          params: {
            token,
            concurso,
            pagina: 1
          }
        });
        this.lista_envios = response.data.envios;
        this.qtd_paginas = response.data.qtd_paginas;
        console.log('Lista de envios', this.lista_envios)


      } catch (error) {
        console.log('Error ao abrir pagina de aprovacao ', error.message);
        if (!concurso)
          await this.$router.push('https://www.ibfc.org.br');
        await this.$router.push('/login/' + concurso);
      }

    })();

  },
  watch: {
    pagination: function (a, b) {
      this.aplica_filtro()
    }
  },
  methods: {
    seta_filtro() {
      localStorage.setItem('filtro', this.filtro_status_titulo);
      // this.pagination = ;
      this.aplica_filtro()
    },
    async aplica_filtro() {
      let token = localStorage.getItem('token');
      let concurso = localStorage.getItem('concurso');
      // let offset = localStorage.getItem('offset');
      let filtro = localStorage.getItem('filtro');

      let response = await axios.get('/aprovador/pega_candidatos_com_titulos',
          {
            params: {
              token,
              concurso,
              pagina: this.pagination,
              filtro_status_titulo: this.filtro_status_titulo,
              busca: this.busca_candidato,
            }
          })

      console.log(`Response`, response)
      this.lista_envios = response.data.envios;
      this.qtd_paginas = response.data.qtd_paginas;
    },
    async visualiza(item) {
      let token = localStorage.getItem('token');
      let concurso = localStorage.getItem('concurso');
      //TODO Fazer a trava para titulo com o candidato todo e nao um titulo apenasZ
      //verifica trava do titulo
      // let response = await axios.get('/aprovador/pega_trava_titulo', {
      //   params:
      //       {
      //         token: token,
      //         num_cand: item.num_cand,
      //         concurso: concurso,
      //         tipo_arquivo: item.tipo_arquivo
      //       }
      // })
      // console.log('response da trava',response.data)
      // if(response.data.trava) {
      //   alert('Esse título está sendo analizado por outro avaliador no momento')
      // }else {
      localStorage.setItem('candidato_a_ser_analisado', JSON.stringify(item));
      await this.$router.push('/aprovadortitulos/interno')
      // }
    },
    async altera_situacao(situacao) {

      this.modal1 = false;
      //Popula dados do concurso;
      let token = localStorage.getItem('token');
      let concurso = localStorage.getItem('concurso');

      try {
        let response = await axios.post('/aprovador/update_situacao', {
          token,
          concurso,
          situacao,
          num_cand: this.candidato_analisado.num_cand,
          tipo_arquivo: this.candidato_analisado.tipo_arquivo,
        });
        this.aplica_filtro();
        // response = await axios.get('/aprovador/pega_candidatos_com_solicitacoes', {
        //   params: {
        //     token,
        //     concurso,
        //     pagina: this.pagination,
        //     filtro_status_titulo: this.filtro_status_titulo,
        //   }
        // });
        // this.lista_envios = response.data.envios;
      } catch (error) {
        console.log('Error ao abrir pagina de aprovacao ', error.message);
        if (!concurso)
          await this.$router.push('https://www.ibfc.org.br');
        await this.$router.push('/loginavaliador/' + concurso + '?login=aprovadortitulos');
      }

    },
    async seta_busca() {
      // this.aplica_filtro();
    }
  }
};
</script>
<style>
.modal-dialog {
  max-width: 80% !important;
  z-index: 1000;
}
</style>
