<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img style="max-height: 75px" v-lazy="dados_concurso.logo_cliente">
                  </a>
                </div>
              </div>
              <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">22</span>-->
                  <!--                                        <span class="description">Número de inscrição</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">10</span>-->
                  <!--                                        <span class="description">Nome</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">89</span>-->
                  <!--                                        <span class="description">CPF</span>-->
                  <!--                                    </div>-->
                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <h3>Protocolo
              </h3>
              <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ dados_concurso.nome_cliente }}
              </div>
              <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>{{ dados_concurso.nome_concurso }}
              </div>
            </div>

            <div class="mt-5 py-4 border-top text-center">
              <div class="btn_enviar row justify-content-center">
                <div class="col-lg-12">
                  <h5>Protocolo </h5>
                  <h6>Número de Procotolo: {{ protocolo }}</h6>

                  <div>

                    <div class="mt-1 pt-5  row justify-content-center">
                      <div class="text-left px-0 col-md-1">
                        <h6><b>Nome:</b></h6>
                      </div>
                      <div class="col-md-9 text-left ">
                        {{ informacoes_cadastrais.nome }}
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div class="text-left px-0 col-md-2">
                        <h6><b>Data de Nascimento:</b></h6>
                      </div>

                      <div class="col-md-3 text-left">
                        {{ informacoes_cadastrais.DataNascimento }}
                      </div>

                      <div class="px-0 col-md-2 text-left">
                        <h6><b>Quantidade de Filhos:</b></h6>
                      </div>
                      <div class=" col-md-3 text-left">
                        {{ informacoes_cadastrais.Filhos }}
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div class="px-0 col-md-2 text-left">
                        <h6><b>Sexo:</b></h6>
                      </div>
                      <div class="col-md-3 text-left">
                        {{ informacoes_cadastrais.Sexo == '1' ? 'M' : 'F' }}
                      </div>
                      <div class="px-0 col-md-2 text-left">
                        <h6><b>Estado Civil:</b></h6>
                      </div>
                      <div class="text-left col-md-3">
                        {{ informacoes_cadastrais.EstadoCivil }}
                      </div>
                    </div>

                    <div class="mt-1 pt-5 row justify-content-center">
                      <div class=" col-md-12">
                        <h5>Documento de identidade</h5>
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div class="px-0 col-md-2 text-left">
                        <h6><b>RG:</b></h6>
                      </div>
                      <div class="text-left col-md-3">
                        {{ informacoes_cadastrais.NumeroIdentidade }}
                      </div>
                      <div class="px-0 col-md-2 text-left">
                        <h6><b>Orgão Emissor:</b></h6>
                      </div>
                      <div class="text-left col-md-3">
                        {{ informacoes_cadastrais.OrgaoEmissor }}
                      </div>
                    </div>


                    <div class="row justify-content-center">
                      <div class="px-0 col-md-2 text-left">
                        <h6><b>Data de expedição:</b></h6>
                      </div>
                      <div class=" col-md-3 text-left">
                        {{ informacoes_cadastrais.DataExpedicao }}
                      </div>
                      <div class="px-0 col-md-2 text-left">
                        <h6><b>Estado de Expedição:</b></h6>
                      </div>
                      <div class=" col-md-3 text-left">
                        {{ informacoes_cadastrais.UfEmissor }}
                      </div>
                    </div>


                    <div class="mt-1  pt-5 row justify-content-center">
                      <div class="col-md-12">
                        <h5>Contatos</h5>
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div class="text-left px-0 col-md-2">
                        <h6><b>Celular DDD</b></h6>
                      </div>
                      <div class=" col-md-2 text-left">
                        {{ informacoes_cadastrais.Ddd }}
                      </div>
                      <div class="text-left px-0 col-md-2">
                        <h6><b>Celular Número</b></h6>
                      </div>
                      <div class=" col-md-4 text-left">
                        {{ informacoes_cadastrais.Telefone }}
                      </div>
                    </div>


                    <div class="row justify-content-center">
                      <div class="text-left px-0 col-md-2">
                        <h6><b>Telefone Residencial DDD</b></h6>
                      </div>
                      <div class=" col-md-2 text-let">
                        {{ informacoes_cadastrais.DddDois }}
                      </div>
                      <div class="text-left px-0 col-md-2">
                        <h6><b>Telefone Residencial Número</b></h6>
                      </div>
                      <div class=" col-md-4 text-let">
                        {{ informacoes_cadastrais.TelefoneDois }}
                      </div>
                    </div>

                    <div class="py-2 row justify-content-center">
                      <div class="text-left px-0 col-md-1 ">
                        <h6><b>Email</b></h6>
                      </div>
                      <div class=" col-md-9 text-left">
                        {{ informacoes_cadastrais.email }}
                      </div>
                    </div>


                    <div class="mt-1 pt-5 row justify-content-center">
                      <div class=" col-md-12">
                        <h5>Endereço</h5>
                      </div>
                    </div>


                    <div class="row justify-content-center">
                      <div class="px-0 col-md-2 text-left ">
                        <h6><b>Rua:</b></h6>
                      </div>
                      <div class=" col-md-6 text-left">
                        {{ informacoes_cadastrais.Endereco }}
                      </div>
                      <div class="px-0 col-md-1 text-left ">
                        <h6><b>Número:</b></h6>
                      </div>
                      <div class=" col-md-2 text-left">
                        {{ informacoes_cadastrais.EnderecoNumero }}
                      </div>
                    </div>


                    <div class="row justify-content-center">
                      <div class="px-0 col-md-2 text-left ">
                        <h6><b>Complemento</b></h6>
                      </div>
                      <div class=" col-md-3 text-left">
                        {{ informacoes_cadastrais.EnderecoComplemento }}
                      </div>
                      <div class="px-0 col-md-1 text-left ">
                        <h6><b>Bairro:</b></h6>
                      </div>
                      <div class=" col-md-2 text-left">
                        {{ informacoes_cadastrais.Bairro }}
                      </div>
                      <div class="px-0 col-md-1 text-left ">
                        <h6><b>Cidade:</b></h6>
                      </div>
                      <div class=" col-md-2 text-left">
                        {{ informacoes_cadastrais.Cidade }}
                      </div>
                    </div>

                    <div class="row justify-content-center">
                      <div class="px-0 col-md-2 text-left">
                        <h6><b>Estado</b></h6>
                      </div>
                      <div class=" col-md-3 text-left">
                        {{ informacoes_cadastrais.Uf }}
                      </div>
                      <div class="px-0 col-md-1 text-left">
                        <h6><b>CEP</b></h6>
                      </div>
                      <div class=" col-md-5 text-left">
                        {{ informacoes_cadastrais.Cep }}
                      </div>
                    </div>


                  </div>
                  <!--FIM da area editavel-->
                  <base-button :disabled="!btnConfirmacao" @click="imprimir" type="primary" class="my-4">
                    Imprimir
                  </base-button>
                  <base-button @click="logout" type="danger" class="my-4">Finalizar</base-button>

                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../components/Modal';

import axios from "axios";

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'

Vue.use(VueViewer)

export default {
  data: function () {
    return {
      dados_candidato: {},
      dados_concurso: {},
      protocolo: "",
      file: "",
      modal1: false,
      btnConfirmacao: true,
      texto: '',
      informacoes_cadastrais: {},
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    Modal: Modal
  }, beforeMount() {
    (async () => {

      //Popula dados do concurso;
      let token = localStorage.getItem('token');
      let concurso = localStorage.getItem('concurso');

      try {
        let response = await axios.get('/form/info_concurso', {params: {token, concurso}})
        this.dados_concurso = response.data;
        response = await axios.get('/form/info_candidato', {params: {token, concurso}})
        this.dados_candidato = response.data;

        response = await axios.get('/alteracaocadastral/info', {params: {token, concurso}})
        this.informacoes_cadastrais = response.data.infos;
        // response = await axios.get('/form/protocolo', {params: {token,concurso}})
        // console.log(response);
        // this.images = response.data.images;
        this.protocolo = localStorage.getItem('protocolo');
      } catch (error) {
        await this.$router.push('/login/' + localStorage.getItem('concurso'))
      }

    })();

  },
  methods: {
    logout() {
      localStorage.setItem('token', '');
      this.$router.push('/login/' + localStorage.getItem('concurso') + '?login=alteracaocadastral')
    },

    imprimir: async function () {
      window.print();
    }
  }
};
</script>
<style scoped>
.file-name {
  margin: 5px;
}
</style>
