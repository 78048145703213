<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img style="max-height: 75px" v-lazy="dados_concurso.logo_cliente">
                  </a>
                </div>
              </div>
              <div class="col-lg-4 mt-5 order-lg-3 text-lg-right align-self-lg-center">
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">22</span>-->
                  <!--                                        <span class="description">Número de inscrição</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">10</span>-->
                  <!--                                        <span class="description">Nome</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">89</span>-->
                  <!--                                        <span class="description">CPF</span>-->
                  <!--                                    </div>-->
                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ dados_concurso.nome_cliente }}
              </div>
              <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>{{ dados_concurso.nome_concurso }}
              </div>
              <h3>Inscrição para oitiva das notas das provas orais</h3>

            </div>

            <div class="row mt-5">
              <h5 class="col-md-2">Nome</h5>
              <div class="col-md-6">
                <base-input v-model="inscricao.nome"></base-input>
              </div>
            </div>

            <div class="row ">
              <h5 class=" col-md-2">CPF</h5>
              <div class="col-md-4">
                <base-input :valid="valida_cpf()" @change="valida_cpf()" v-mask="'###.###.###-##'"
                            v-model="inscricao.cpf"></base-input>
              </div>

              <h5 class="col-md-2">Data de Nascimento</h5>
              <div class="col-md-3">
                <base-input v-mask="'##/##/####'" v-model="inscricao.data_nasc"></base-input>
              </div>
            </div>

            <div class="row">
              <h5 class="col-md-4">Documento de Identidade (RG)</h5>
              <div class="col-md-6">
                <base-input v-model="inscricao.rg"></base-input>
              </div>
            </div>

            <div class="row">
              <h5 class="col-md-2">E-mail</h5>
              <div class="col-md-6">
                <base-input v-model="inscricao.email"></base-input>
              </div>
            </div>

            <div class="mt-1 pt-5 row justify-content-center">
              <div class=" col-md-12">
                <h5>Telefone</h5>
              </div>
            </div>

            <div class="row ml-2">
              <div style="margin-top: 10px" class="col-md-2">
                <h6><b>Celular</b></h6>
              </div>
              <div class=" col-md-3">
                <base-input v-model="inscricao.celular" v-mask="'(##) #####-####'"></base-input>
              </div>

              <div style="margin-top: 10px" class="col-md-2">
                <h6><b>Residencial</b></h6>
              </div>
              <div class=" col-md-3">
                <base-input v-model="inscricao.residencial" v-mask="'(##) #####-####'"></base-input>
              </div>
            </div>


            <!--            <div class="row mt-4 justify-content-center">-->
            <!--              <vue-hcaptcha v-on:verify="valida_captcha" sitekey="f7990c82-4cee-42ee-abd3-5ab1bad7a272"></vue-hcaptcha>-->
            <!--            </div>-->

            <div class="mt-1 py-4 border-top text-center">
              <div class="btn_enviar row justify-content-center">
                <div class="col-lg-9">
                  <div class="btnConfirmacao">
                    <base-checkbox class="mb-3" v-model="btnConfirmacao">
                      <b>Declaro que as informações acima estão corretas e são verdadeiras.</b>
                    </base-checkbox>
                  </div>
                  <base-button @click="envia_inscricao()" :disabled="!btnConfirmacao" native-type="submit"
                               type="primary" class="my-4">
                    Enviar
                    Inscrição
                  </base-button>
                  <!--                  <base-button native-type="submit" type="danger" class="my-4">Sair</base-button>-->
                  <modal :show.sync="modal1">
                    <h6 slot="header" class="modal-title" id="modal-title-default">Erro ao protocolar envio de
                      Impugnação do Edital</h6>

                    <h5 v-if="validacao_cpf_ja_cadastrado">CPF já inscrito!</h5>
                    <b v-if=" validacao_cpf_ja_cadastrado">Informe um novo CPF para uma nova inscrição.</b>
                    <br>

                    <h5 v-if="!valida_cpf()">O CPF é inválido</h5>
                    <b v-if="!valida_cpf()">Digite um CPF válido para continuar.</b>

                    <br>

                    <h5 v-if="!validacao_nome">Nome inválido</h5>
                    <b v-if="!validacao_nome">Informe o nome completo.</b>

                    <h5 v-if="!validacao_celular">Celular inválido</h5>
                    <b v-if="!validacao_celular">Informe o telefone celular corretamente.</b>
                    <br>

                    <h5 v-if="!validacao_email">Email inválido</h5>
                    <b v-if="!validacao_email">Informe o email corretamente.</b>
                    <br>

                    <h5 v-if="!validacao_nasc">Data de nascimento inválida</h5>
                    <b v-if="!validacao_nasc">Informe a data de nascimento corretamente.</b>
                    <br>

                    <h5 v-if="!validacao_rg">Documento de indentidate inválido</h5>
                    <b v-if="!validacao_rg">Informe o documento corretamente.</b>
                    <br>



                    <template slot="footer">
                      <base-button type="link" class="ml-auto" @click="modal1 = false">Voltar
                      </base-button>
                    </template>
                  </modal>

                  <modal :show.sync="modal_limite">
                    <h6 slot="header" class="modal-title" id="modal-limite">Limite de inscrições alcançado</h6>


                    <h5>Considerando as limitações impostas pelos protocolos sanitários vigentes</h5>
                    <p style="font-size:1.3em">Somente será concedida admissão à sessão de 15 (quinze) assistentes.</p>
                    <br>
                    <br>
                    <p>Transmissão on line, pelo canal institucional de YouTube da PGM, disponível no endereço
                      eletrônico <a href="https://www.youtube.com/c/PGMRio">https://www.youtube.com/c/PGMRio</a></p>


                  </modal>

                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from '../components/Modal';
import axios from "axios";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import {cpf} from 'cpf-cnpj-validator';
import BaseDropdown from "@/components/BaseDropdown";
import validate from "email-validator";

export default {
  data: function () {
    return {
      concurso: '',
      validacao_captcha: false,
      validacao_celular: false,
      validacao_email: false,
      validacao_nome: false,
      validacao_nasc: false,
      validacao_rg: false,
      validacao_cpf_ja_cadastrado:false,
      inscricao: {cpf: '', nome: '', data_nasc: '', celular: '', residencial: '', rg: '', email: ''},
      dados_concurso: {},
      file: "",
      protocolo_gerado: '',
      modal1: false,
      modal_limite: false,
      btnConfirmacao: false,
    }
  },
  components: {
    BaseDropdown,
    Modal: Modal,
    VueHcaptcha
  },
  beforeMount() {
    (async () => {
      alert("PRAZO ENCERRADO!");

      //Popula dados do concurso;
      this.concurso = '1916' //TODO Pegar da URL
      localStorage.setItem('tipo_pagina', 'inscricao');
      localStorage.setItem('concurso', this.concurso);

      //Pega qtd de pessoas cadastradas
      let response;

      try {
        response = await axios.get('/form/info_concurso_impugnacao', {params: {concurso: this.concurso}})
        this.dados_concurso = response.data;

      } catch (error) {
        alert(error)
        // window.location.replace('https://www.ibfc.org.br')
      }


      response = await axios.get('/pgm/qtd_inscritos').catch(error => console.error);


      if (response.data.qtd_inscritos >= 15) {
        const sleep = async (time) => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              resolve()
            }, time)
          })
        }
        this.modal_limite = true;
        await sleep(200);
        document.querySelector("#app > main > div > section.section.section-skew > div > div > div > div.mt-1.py-4.border-top.text-center > div > div > div.modal.fade.show.d-block > div > div > div.modal-header > button").style.display = 'none'
      }


    })();

  },
  methods: {
    valida_captcha(token, ekey) {
      this.validacao_captcha = true;
    },
    valida_cpf() {
      if (!this.inscricao.cpf)
        return undefined;

      if (!cpf.isValid(this.inscricao.cpf)) {
        this.validacao_cpf = false;
        return false;
      } else {
        this.validacao_cpf = true;
        return true;
      }

    },
    async envia_inscricao() {

      alert("PRAZO ENCERRADO!!!");
      return;

      this.inscricao.nome.split(' ').length <= 1 ? this.validacao_nome = false : this.validacao_nome = true;
      this.inscricao.celular.length <= 10 ? this.validacao_celular = false : this.validacao_celular = true;
      this.inscricao.celular[5] != '9' ? this.validacao_celular = false : this.validacao_celular = true;
      let email_validator = require('email-validator')
      this.validacao_email = email_validator.validate(this.inscricao.email);
      let data_nasc = this.inscricao.data_nasc.split('/');
      this.validacao_nasc = this.inscricao.data_nasc.length < 10 || (data_nasc[2] < 1901) ? false : true;
      this.validacao_rg = this.inscricao.rg.length < 4 ? false : true;


      console.log(this.validacao_email)
      console.log(`Digito`, data_nasc[2], data_nasc)
      let data = new Date();
      // let data_fim = new Date(Date.UTC(2021, 8, 1, 12, 0, 0))
      let data_fim = new Date(Date.UTC(2021, 8, 2, 20, 0, 0))

      // if (data > data_fim) {
      //   alert(`Prazo encerrado`);
      //   return;
      // }

      if (!this.valida_cpf() || !this.validacao_nome || !this.validacao_nasc || !this.validacao_rg || !this.validacao_celular || !this.validacao_email) {
        this.modal1 = true;
        return;
      }

      console.log('Estou enviando.:', this.inscricao)

      let response = await axios.post('/pgm/insere_inscricao', {
        concurso: this.concurso,
        cpf: this.inscricao.cpf,
        nome: this.inscricao.nome,
        data_nasc: this.inscricao.data_nasc,
        celular: this.inscricao.celular,
        residencial: this.inscricao.residencial,
        rg: this.inscricao.rg,
        email: this.inscricao.email,
      })
          .catch((error) => {

            let msg = error.response.data.message
            if (msg === 'Limite de inscrições atingido') {
              this.modal_limite = true
            } else {
              this.validacao_cpf_ja_cadastrado = true;
              this.modal1 = true;
              // alert(`Erro ${error.response.data.message}`)
            }

            console.log(123, error, error.response.data.message);

          })

      console.log('Estou recebendo:', response.data);
      localStorage.setItem('inscricao', JSON.stringify(response.data.data));


      await this.$router.push('/pgmprotocolo');
    }

  }
}
;
</script>
<style scoped>
.file-name {
  margin: 5px;
}
</style>
