<template>
  <footer class="footer has-cards">
    <div class="container container-lg">

      <div class="container">

        <hr>
        <div class="row align-items-center justify-content-md-between">
          <div class="col-md-6">
            <div class="copyright">
              &copy; {{ year }}
              <a href="https://www.ibfc.org.br" target="_blank" rel="noopener">IBFC</a>
<!--              <a href="https://www.binarcode.com" target="_blank" rel="noopener">Binar Code</a>-->
            </div>
          </div>
          <div class="col-md-6">
            <ul class="nav nav-footer justify-content-end">
              <li class="nav-item">
                <a href="https://www.ibfc.org.br" class="nav-link" target="_blank" rel="noopener">Instituto Brasileiro de Formação e Capacitação</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
