<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img style="max-height: 75px" v-lazy="dados_concurso.logo_cliente">
                  </a>
                </div>
              </div>
              <div class="col-lg-4 mt-5 order-lg-3 text-lg-right align-self-lg-center">
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">22</span>-->
                  <!--                                        <span class="description">Número de inscrição</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">10</span>-->
                  <!--                                        <span class="description">Nome</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">89</span>-->
                  <!--                                        <span class="description">CPF</span>-->
                  <!--                                    </div>-->
                </div>
              </div>
            </div>


            <div>
            <div class="text-center mt-5">
              <div class="h6 font-weight-300" style="width: 100%"><i class="ni location_pin mr-2"></i>
                {{ dados_concurso.nome_cliente }}
              </div>
              <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>{{ dados_concurso.nome_concurso }}
              </div>
              <h3 class="mt-5">Solicitação da Devolução do Valor da Inscrição</h3>
              <h5>Para onde deverá ser feita a devolução?</h5>
            </div>

            <div class="row">
              <h6 class="col-md-3">Nome Completo</h6>
              <div class="col-md-8">
                <base-input v-model="reembolso.nome"></base-input>
              </div>
            </div>

            <div class="row">
              <h6 class="col-md-3">RG</h6>
              <div class="col-md-8">
                <base-input v-model="reembolso.rg"></base-input>
              </div>
            </div>

            <div class="row">
              <h6 class="col-md-3">Nome do Banco</h6>
              <div class="col-md-8">
                <base-input v-model="reembolso.banco"></base-input>
              </div>
            </div>

            <div class="row">
              <h6 class="col-md-3">Agência com dígito</h6>
              <div class="col-md-8">
                <base-input v-model="reembolso.agencia"></base-input>
              </div>
            </div>

            <div class="row">
              <h6 class="col-md-3">Conta Corrente</h6>
              <div class="col-md-8">
                <base-input v-model="reembolso.conta_corrente"></base-input>
              </div>
            </div>

            <div class="row">
              <h6 class="col-md-3">CPF do titular da conta</h6>
              <div class="col-md-8">
                <base-input v-mask="'###.###.###-##'" v-model="reembolso.cpf"></base-input>
              </div>
            </div>

            <div class="row">
              <h6 class="col-md-3">Telefone para contato</h6>
              <div class="col-md-8">
                <base-input v-mask="'(##) #####-####'" v-model="reembolso.telefone"></base-input>
              </div>
            </div>


            <div class="row">
              <h6 class="col-md-3 mt-5">Argumentação</h6>
              <div class="col-md-8">
                <textarea maxlength="5000" class="form-control mt-4" rows="3" v-model="reembolso.motivo"></textarea>
                Limite de caracteres {{ reembolso.motivo.length }}/5000

              </div>
            </div>

<!--            <div class="row justify-content-center">-->
<!--              <h4 class="col-md-12 mt-5">Envio de arquivos</h4>-->
<!--              <h6>Utilize o campo abaixo para enviar comprovantes, documentos e anexar qualquer outra informação que-->
<!--                achar relevante.<br>-->
<!--               </h6>-->
<!--            </div>-->
<!--            <div class="row justify-content-center">-->
<!--              <div class="col-lg-9">-->
<!--                <div class="field">-->
<!--                  <vue-dropzone ref="drop_reembolso" id="drop_solicitacao_especial"-->
<!--                                :options="drop_reembolso"></vue-dropzone>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <!--            <div class="row mt-4 justify-content-center">-->
            <!--              <vue-hcaptcha v-on:verify="valida_captcha" sitekey="f7990c82-4cee-42ee-abd3-5ab1bad7a272"></vue-hcaptcha>-->
            <!--            </div>-->

            <div class="mt-1 py-4 border-top text-center">
              <div class="btn_enviar row justify-content-center">
                <div class="col-lg-9">
                  <div class="btnConfirmacao">
                    <base-checkbox class="mb-3" v-model="btnConfirmacao">
                      <b>Declaro que as informações acima estão corretas e são verdadeiras.</b>
                    </base-checkbox>
                  </div>
                  <base-button @click="envia_reembolso()" :disabled="!btnConfirmacao" native-type="submit"
                               type="primary" class="my-4">
                    Enviar
                    Solicitação
                  </base-button>
                  </div>
                  <!--                  <base-button native-type="submit" type="danger" class="my-4">Sair</base-button>-->
                  <modal :show.sync="modal1">
                    <h6 slot="header" class="modal-title" id="modal-title-default">Erro ao enviar a solicitação</h6>


                    <h5 v-if="!valida_cpf()">O CPF é inválido</h5>
                    <b v-if="!valida_cpf()">Digite um CPF válido para continuar</b>

                    <br>
                    <br>

                    <h5 v-if="!validacao_nome">Informe um Nome</h5>
                    <b v-if="!validacao_nome">É necessário informar o nome completo</b>

                    <br>
                    <br>

                    <h5 v-if="!validacao_rg">É necessário informar um documento de identidade</h5>
                    <b v-if="!validacao_rg">Informe um documento de identidade para continuar</b>

                    <br>
                    <br>

                    <h5 v-if="!validacao_nome_banco">Nome do Banco</h5>
                    <b v-if="!validacao_nome_banco">É necessário colocar o nome do banco completo para continuar</b>
                    <br>
                    <br>

                    <h5 v-if="!validacao_telefone">Telefone</h5>
                    <b v-if="!validacao_telefone">É necessário informar um telefone para contato</b>
                    <template slot="footer">
                      <base-button type="link" class="ml-auto" @click="modal1 = false">Voltar
                      </base-button>
                    </template>
                  </modal>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import Modal from '../components/Modal';
import axios from "axios";
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';
import {cpf} from 'cpf-cnpj-validator';
import BaseDropdown from "@/components/BaseDropdown";
import vue2Dropzone from 'vue2-dropzone';


export default {
  data: function () {
    return {
      reembolso_liberado:false,
      concurso: '2317',
      cargo_selecionado: 'Selecione a especialidade',
      tipo_impugnacao: 'Selecione o tipo',
      validacao_nome: false,
      validacao_nome_banco: false,
      validacao_rg: false,
      validacao_telefone:false,
      validacao_tipo_impugnacao: false,
      validacao_captcha: false,
      validacao_motivo: false,
      reembolso: {
        cpf: '', nome: '', telefone: '', conta_corrente: '', agencia: '',
        banco: '', rg: '', motivo: '',num_cand:''
      },
      dados_concurso: {},
      file: "",
      protocolo_gerado: '',
      modal1: false,
      btnConfirmacao: false,
      drop_reembolso: {
        timeout: 100000,
        url: this.$http.defaults.baseURL + '/form/upload_files',
        thumbnailWidth: 150,
        maxFilesize: 4,
        dictRemoveFile: "Remover",
        addRemoveLinks: true,
        acceptedFiles: 'image/jpg,image/jpeg,image/png,application/pdf',
        autoProcessQueue: false,
        dictDefaultMessage: "Clique ou arraste para enviar arquivos",
        headers: {
          token: localStorage.getItem('token'),
          tipoarquivo: 'reembolso',
          protocolo: '',
          origemarquivo: 'documentos'
        }
      }
    }
  },
  components: {
    BaseDropdown,
    Modal: Modal,
    vueDropzone: vue2Dropzone

  },
  beforeMount() {
    (async () => {

      //Popula dados do concurso;
      this.concurso = this.$route.query.concurso;
      this.num_cand = this.$route.query.num_cand;
      this.cpf = this.$route.query.cpf;
      this.token = localStorage.getItem('token');
      this.protocolo_gerado = `${this.concurso}${this.num_cand.replace('-', '')}${(new Date().toISOString()).substring(12)
          .replace(':', '').replace(':', '').replace('Z', '').replace('.', '')}`

      try {

        //pega token do numero de inscricao passado, ao inves do token que veio
        const response_token = await axios.post('/user/login', {
          username: this.num_cand,
          password: this.cpf,
          concurso: this.concurso,
          origem_arquivo: 'reembolso',
        });
        this.token_reembolso = response_token.data.token;

        let response = await axios.get('/form/info_concurso_impugnacao', {params: {concurso: this.concurso}})
        this.dados_concurso = response.data;
        // localStorage.setItem('protocolo', this.protocolo_gerado);
      } catch (error) {
        // alert(`Um erro ocorreu, tente novamente.`)
        console.log(`Um erro ocorreu`, error.message);
        // await this.$router.push('/login/' + concurso+'?login=isencao');
      }

    })();

  },
  methods: {
    valida_cpf() {
      if (!this.reembolso.cpf)
        return undefined;

      if (!cpf.isValid(this.reembolso.cpf)) {
        this.validacao_cpf = false;
        return false;
      } else {
        this.validacao_cpf = true;
        return true;
      }
    },
    verifica_reembolso(){

    let candidatos_liberados=[{
      num_cand:'1234567-8',
      cpf:'399.140.958-52'
    }]

      let cpf_login = this.reembolso.cpf
      let num_cand_login = this.reembolso.num_cand

      let candidato_liberado = candidatos_liberados.filter(item=>{
        if(item.num_cand===num_cand_login && item.cpf===cpf_login)
          return true
        return false
      })

      console.log(cpf_login,num_cand_login,candidato_liberado)
      if(candidato_liberado.length>0)
        this.reembolso_liberado=true;

      else{
        alert("Inscrição ou CPF inválidos para pedir reembolso, verifique os dados e se essa inscrição se enquadra nas regras de reembolso.")
      }
    },
    async envia_reembolso() {

      // this.reembolso.cargo.length === 0 ? this.validacao_cargo = false : this.validacao_cargo = true;
      // this.impugnacao.motivo.length === 0 ? this.validacao_motivo = false : this.validacao_motivo = true;
      // this.impugnacao.tipo_impugnacao.length === 0 ? this.validacao_tipo_impugnacao = false : this.validacao_tipo_impugnacao = true;
      let data = new Date();


      let data_fim = new Date('2023-10-28 02:59:00Z')

      if (data > data_fim) {
        alert(`Prazo encerrado`);
        return;
      }

      this.reembolso.nome.length <10 ? this.validacao_nome = false : this.validacao_nome = true;
      this.reembolso.rg.length <3 ? this.validacao_rg= false : this.validacao_rg= true;
      this.reembolso.banco.length <3 ? this.validacao_nome_banco= false : this.validacao_nome_banco= true;
      this.reembolso.telefone.length <15 ? this.validacao_telefone= false : this.validacao_telefone= true;

      if (!this.valida_cpf() || !this.validacao_nome || !this.validacao_rg || !this.validacao_nome_banco || !this.validacao_telefone) {
        console.log(!this.valida_cpf() , this.validacao_nome , this.validacao_rg , this.validacao_nome_banco , this.validacao_telefone,)
        this.modal1 = true;
        return;
      }

      // let arquivos_na_fila = 0;
      // arquivos_na_fila += this.$refs.drop_reembolso !== undefined ? this.$refs.drop_reembolso.getQueuedFiles().length : 0;

      // if (arquivos_na_fila == 0) {
      //   // this.modal1 = true
      //   alert('É necessário enviar arquivos');
      //   return
      // }

      // this.drop_reembolso.headers.protocolo = this.protocolo_gerado;
      // this.drop_reembolso.headers.token=this.token_reembolso;
      // await this.$refs.drop_reembolso.processQueue();
      //


      console.log('Estou enviando.:', this.reembolso)

      let response = await axios.post('/user/reembolso', {
        cpf: this.reembolso.cpf,
        nome: this.reembolso.nome,
        telefone: this.reembolso.telefone,
        conta_corrente: this.reembolso.conta_corrente,
        agencia: this.reembolso.agencia,
        banco: this.reembolso.banco,
        rg: this.reembolso.rg,
        argumento:this.reembolso.motivo,
        concurso: this.concurso,
        num_cand: this.num_cand,
        protocolo: this.protocolo_gerado,
        cpf_titular_conta:this.reembolso.cpf,
        cargo:''
      },{headers:{token:this.token_reembolso}})
      // console.log('Estou recebendo:', response.data);
      // localStorage.setItem('impugnacao', JSON.stringify(response.data));


      // await this.$router.push('/protocoloreembolso');


      const interval = setInterval(async () => {

        let drops = [];
        if (this.$refs.drop_jurado !== undefined)
          drops.push(this.$refs.drop_reembolso.dropzone.files);

        let flag = true;
        const verifica_arquivos = (drop) => {
          for (let item of drop) {
            console.log(item)
            if (item.status !== 'success') {
              flag = false;
            }
          }
        }

        for (let drop of drops) {
          verifica_arquivos(drop)
        }
        if (flag) {
          clearInterval(interval);
          localStorage.setItem('reembolso', this.protocolo_gerado);
          return await this.$router.push('/protocoloreembolso');

        }
      }, 2000)
    }

  }
}
;
</script>
<style scoped>
.file-name {
  margin: 5px;
}

.profile-page {
  margin-bottom: 300em;
}
</style>
