<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img style="max-height:75px;" v-lazy="dados_concurso.logo_cliente">
                  </a>
                </div>
              </div>
              <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">22</span>-->
                  <!--                                        <span class="description">Número de inscrição</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">10</span>-->
                  <!--                                        <span class="description">Nome</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">89</span>-->
                  <!--                                        <span class="description">CPF</span>-->
                  <!--                                    </div>-->
                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <h3>Alteração Cadastral
              </h3>
              <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ dados_concurso.nome_cliente }}
              </div>
              <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>{{ dados_concurso.nome_concurso }}
              </div>
            </div>
            <div class="mt-5 py-3 border-top text-center">
              <h5>Dados do Candidato</h5>
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <h6 class="text-left"><b>Nome: </b>{{ dados_candidato.nome }}</h6>
                  <h6 class="text-left"><b>Número de Inscrição: </b>{{ dados_candidato.num_cand }}</h6>
                  <h6 class="text-left"><b>Email: </b>{{ dados_candidato.email }}</h6>
                  <h6 class="text-left"><b>Cargo: </b>{{ dados_candidato.cargo }}</h6>
                </div>
              </div>
            </div>

            <div class="mt-1 py-4 border-top text-center">
              <div class="btn_enviar row justify-content-center">
                <h6>Mude o texto para alterar as suas informações. Quando acabar aperte o botão <b>Enviar Alterações
                  Cadastrais</b></h6>
              </div>
           <h6 v-if="dados_concurso.concurso ==='2317T'" class="mt-3">Atenção: Para concluir a alteração da data de nascimento e/ou CPF, além de preencher o formulário abaixo, é necessário também fazer o upload de um documento com foto
                  no link abaixo:</h6>
              <a  v-if="dados_concurso.concurso ==='2317T'" class="btn " style="background-color: #0d778e;color: white"  target="_blank" href="https://sistema-file-uploader.ibfc.org.br/login/2317T?login=documentos">Enviar documento com foto</a>

              <div class="mt-1 pt-5  row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-1">
                  <h6>Nome</h6>
                </div>
                <div class=" col-md-9">
                  <base-input @change="muda_info($event,'nome')" :value="informacoes_cadastrais.nome"></base-input>
                </div>
              </div>

              <div class="mt-1 pt-5  row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-1">
                  <h6>CPF</h6>
                </div>
                <div class=" col-md-9">
                  <base-input   @change="muda_info($event,'cpf')" :value="informacoes_cadastrais.cpf" v-mask="'###.###.###-##'"></base-input>
                </div>
              </div>


              <div class="row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>Data de Nascimento</h6>
                </div>

                <div style="margin-top: 10px" class="px-0 col-md-2">

                </div>
                <div class=" col-md-3">

                  <base-input @change="muda_info($event,'DataNascimento')" addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker slot-scope="{focus, blur}"
                                 @on-open="focus"
                                 @on-close="blur"
                                 :config="flatPicker_options"
                                 class="form-control datepicker"
                                 v-model="informacoes_cadastrais.DataNascimento">
                    </flat-picker>
                  </base-input>

                </div>
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>Quantidade de Filhos</h6>
                </div>
                <div class=" col-md-3">
                  <base-input @change="muda_info($event,'Filhos')" type="number" min="0"
                              :value="informacoes_cadastrais.Filhos"></base-input>
                </div>
              </div>

              <div class="row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>Sexo</h6>
                </div>
                <div class=" col-md-3">
                  <base-input>
                    <select @change="muda_info($event,'Sexo')" :value="informacoes_cadastrais.Sexo"
                            class="custom-select">
                      <option value="1">Masculino</option>
                      <option value="2">Feminino</option>
                    </select>
                  </base-input>
                </div>
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>Estado Civil</h6>
                </div>
                <div class=" col-md-3">
                  <select @change="muda_info($event,'EstadoCivil')" :value="informacoes_cadastrais.EstadoCivil"
                          class="custom-select">
                    <option value="Outros">Outros</option>
                    <option value="Solteiro">Solteiro</option>
                    <option value="Separado">Separado</option>
                    <option value="Divorciado">Divorciado</option>
                    <option value="Casado">Casado</option>
                    <option value="Viúvo">Viúvo</option>
                  </select>
                </div>
              </div>


              <div class="mt-1 pt-5 row justify-content-center">
                <div class=" col-md-12">
                  <h5>Documento de identidade</h5>
                </div>
              </div>

              <div class="row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>RG</h6>
                </div>
                <div class=" col-md-3">
                  <base-input @change="muda_info($event,'NumeroIdentidade')"
                              :value="informacoes_cadastrais.NumeroIdentidade"></base-input>
                </div>
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>Orgão Emissor</h6>
                </div>
                <div class=" col-md-3">
                  <base-input @change="muda_info($event,'OrgaoEmissor')"
                              :value="informacoes_cadastrais.OrgaoEmissor"></base-input>
                </div>
              </div>


              <div class="row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>Data de expedição</h6>
                </div>
                <div class=" col-md-3">
                  <base-input :valid="data_expedicao_validador" addon-left-icon="ni ni-calendar-grid-58">
                    <flat-picker slot-scope="{focus, blur}"
                                 @on-change="muda_info($event,'DataExpedicao')"
                                 @on-open="focus"
                                 @on-close="blur"
                                 :config="flatPicker_options"
                                 class="form-control datepicker"
                                 v-model="informacoes_cadastrais.DataExpedicao">
                    </flat-picker>
                  </base-input>
                </div>
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>Estado de Expedição</h6>
                </div>
                <div class=" col-md-3">
                  <select @change="muda_info($event,'UfEmissor')" :value="informacoes_cadastrais.UfEmissor"
                          class="custom-select">
                    <option value="RO">RO</option>
                    <option value="AC">AC</option>
                    <option value="AM">AM</option>
                    <option value="RR">RR</option>
                    <option value="PA">PA</option>
                    <option value="AP">AP</option>
                    <option value="TO">TO</option>
                    <option value="MA">MA</option>
                    <option value="PI">PI</option>
                    <option value="CE">CE</option>
                    <option value="RN">RN</option>
                    <option value="PB">PB</option>
                    <option value="PE">PE</option>
                    <option value="AL">AL</option>
                    <option value="SE">SE</option>
                    <option value="BA">BA</option>
                    <option value="MG">MG</option>
                    <option value="ES">ES</option>
                    <option value="RJ">RJ</option>
                    <option value="SP">SP</option>
                    <option value="PR">PR</option>
                    <option value="SC">SC</option>
                    <option value="RS">RS</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="GO">GO</option>
                    <option value="DF">DF</option>
                  </select>
                </div>
              </div>


              <div class="mt-1  pt-5 row justify-content-center">
                <div class="col-md-12">
                  <h5>Contatos</h5>
                </div>
              </div>

              <div class="row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6><b>Telefone Celular:</b> DDD</h6>
                </div>
                <div class=" col-md-2">
                  <base-input type="number" @change="muda_info($event,'Ddd')"
                              :value="informacoes_cadastrais.Ddd"></base-input>
                </div>
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6><b>Telefone Celular:</b> Número</h6>
                </div>
                <div class=" col-md-3">
                  <base-input :valid="telefone_validador"  @change="muda_info($event,'Telefone')"
                              :value="informacoes_cadastrais.Telefone"></base-input>
                </div>
              </div>


              <div class="row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6><b>Telefone Residencial:</b> DDD</h6>
                </div>
                <div class=" col-md-2">
                  <base-input type="number" @change="muda_info($event,'DddDois')"
                              :value="informacoes_cadastrais.DddDois"></base-input>
                </div>
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6><b>Telefone Residencial:</b> Número</h6>
                </div>
                <div class=" col-md-3">
                  <base-input  @change="muda_info($event,'TelefoneDois')"
                              :value="informacoes_cadastrais.TelefoneDois"></base-input>
                </div>
              </div>

              <div class="py-2 row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-1">
                  <h6>Email</h6>
                </div>
                <div class=" col-md-6">
                  <base-input type="email" @change="muda_info($event,'email')"
                              :value="informacoes_cadastrais.email"></base-input>
                </div>
              </div>

              <div class="mt-1 pt-5 row justify-content-center">
                <div class=" col-md-12">
                  <h5>Endereço</h5>
                </div>
              </div>


              <div class="row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>Rua</h6>
                </div>
                <div class=" col-md-6">
                  <base-input @change="muda_info($event,'Endereco')"
                              :value="informacoes_cadastrais.Endereco"></base-input>
                </div>
                <div style="margin-top: 10px" class="px-0 col-md-1">
                  <h6>Número</h6>
                </div>
                <div class=" col-md-2">
                  <base-input @change="muda_info($event,'EnderecoNumero')"
                              :value="informacoes_cadastrais.EnderecoNumero"></base-input>
                </div>
              </div>


              <div class="row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>Complemento</h6>
                </div>
                <div class=" col-md-3">
                  <base-input @change="muda_info($event,'EnderecoComplemento')"
                              :value="informacoes_cadastrais.EnderecoComplemento"></base-input>
                </div>
                <div style="margin-top: 10px" class="px-0 col-md-1">
                  <h6>Bairro</h6>
                </div>
                <div class=" col-md-2">
                  <base-input @change="muda_info($event,'Bairro')" :value="informacoes_cadastrais.Bairro"></base-input>
                </div>
                <div style="margin-top: 10px" class="px-0 col-md-1">
                  <h6>Cidade</h6>
                </div>
                <div class=" col-md-2">
                  <base-input @change="muda_info($event,'Cidade')" :value="informacoes_cadastrais.Cidade"></base-input>
                </div>
              </div>

              <div class="row justify-content-center">
                <div style="margin-top: 10px" class="px-0 col-md-2">
                  <h6>Estado</h6>
                </div>
                <div class=" col-md-2">
                  <select @change="muda_info($event,'Uf')" :value="informacoes_cadastrais.Uf"
                          class="custom-select">
                    <option value="RO">RO</option>
                    <option value="AC">AC</option>
                    <option value="AM">AM</option>
                    <option value="RR">RR</option>
                    <option value="PA">PA</option>
                    <option value="AP">AP</option>
                    <option value="TO">TO</option>
                    <option value="MA">MA</option>
                    <option value="PI">PI</option>
                    <option value="CE">CE</option>
                    <option value="RN">RN</option>
                    <option value="PB">PB</option>
                    <option value="PE">PE</option>
                    <option value="AL">AL</option>
                    <option value="SE">SE</option>
                    <option value="BA">BA</option>
                    <option value="MG">MG</option>
                    <option value="ES">ES</option>
                    <option value="RJ">RJ</option>
                    <option value="SP">SP</option>
                    <option value="PR">PR</option>
                    <option value="SC">SC</option>
                    <option value="RS">RS</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="GO">GO</option>
                    <option value="DF">DF</option>
                  </select>
                </div>
                <div style="margin-top: 10px" class="px-0 col-md-1">
                  <h6>CEP</h6>
                </div>
                <div class=" col-md-3">
                  <base-input type="number" @change="muda_info($event,'Cep')"
                              :value="informacoes_cadastrais.Cep"></base-input>
                </div>
              </div>


            </div>

            <div class="mt-1 py-4 border-top text-center">
              <div class="btn_enviar row justify-content-center">
                <div class="col-lg-9">
                  <div class="btnConfirmacao">
                    <base-checkbox class="mb-3" v-model="btnConfirmacao">
                      <b>Declaro que as informações acima estão corretas e são verdadeiras.</b>
                    </base-checkbox>
                  </div>
                  <base-button :disabled="!btnConfirmacao" @click="envia_info()" native-type="submit"
                               type="primary" class="my-4">
                    Enviar Alterações Cadastrais
                  </base-button>
<!--                  <base-button native-type="submit" type="danger" class="my-4">Sair</base-button>-->
                  <modal :show.sync="modal1">
                    <h6 slot="header" class="modal-title" id="modal-title-default">Erro ao protocolar envio de
                      arquivo</h6>

                    <h5>Erro encontrado</h5>
                    <b v-if="telefone_validador!=undefined && !telefone_validador">Verifique o número de telefone
                      celular e envie novamente</b>
                    <br>
                       <b v-if="telefonedois_validador!=undefined && !telefonedois_validador">Verifique o número de telefone
                      Residencial e envie novamente</b>
                    <br>
                    <b v-if="data_expedicao_validador!=undefined && !data_expedicao_validador">Data de expedicão do
                      documento de identidade não pode ser menor que nascimento</b>

                    <template slot="footer">
                      <base-button type="link" class="ml-auto" @click="modal1 = false">Voltar
                      </base-button>
                    </template>
                  </modal>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../components/Modal';
import axios from "axios";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {Portuguese} from "flatpickr/dist/l10n/pt.js"



export default {
  data: function () {
    return {
      dados_candidato: {modulos: {jurado: false, pcd: false, solicitacao_especial: false, nome_social: false}},
      dados_concurso: {},
      file: "",
      informacoes_cadastrais: {},
      protocolo_gerado: '',
      modal1: false,
      btnConfirmacao: false,
      flatPicker_options: {
        allowInput: true,
        locale: Portuguese,
        dateFormat: 'd/m/Y'
      },
      dropzoneOptions: {
        timeout: 100000,
        url: this.$http.defaults.baseURL + '/form/upload_files',
        thumbnailWidth: 150,
        maxFilesize: 20,
        dictRemoveFile: "Remover",
        addRemoveLinks: true,
        autoProcessQueue: false,
        dictDefaultMessage: "Clique ou arraste para enviar arquivos",
        headers: {token: localStorage.getItem('token'), drop_tipo: '', protocolo: ''}
      }
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    Modal: Modal,
    flatPicker: flatPicker,

  },
  beforeMount() {
    (async () => {
      //Popula dados do concurso;
      let token = localStorage.getItem('token');
      let concurso = localStorage.getItem('concurso');
      try {
        let response = await axios.get('/form/info_concurso', {params: {token, concurso}})
        this.dados_concurso = response.data;
        response = await axios.get('/form/info_candidato', {params: {token, concurso}})
        this.dados_candidato = response.data;

        response = await axios.get('/form/info_protocolo', {params: {token, concurso}});
        this.protocolo_gerado = response.data.protocolo;

        response = await axios.get('/alteracaocadastral/info', {params: {token, concurso}})
        this.informacoes_cadastrais = response.data.infos;

      } catch (error) {
        await this.$router.push('/login/' + concurso);
      }

    })();

  },
  methods: {
    async muda_info(evento, variavel) {

      if (variavel === 'Telefone' && (evento.target.value[0] != '9' || evento.target.value.length != 10)) {
        this.telefone_validador = false;
        this.modal1 = true;
      }


      if (variavel === 'DataExpedicao') {

        let data_rg = evento;
        let data_nascimento = this.informacoes_cadastrais[`DataNascimento`];

        data_nascimento = new Date(data_nascimento.split('/')[2] + '-' + data_nascimento.split('/')[1] + '-' + data_nascimento.split('/')[0])
        data_rg = new Date(data_rg)


        console.log(data_nascimento - data_rg, data_nascimento, data_rg)
        if ((data_rg - data_nascimento) <= 0) {
          this.data_expedicao_validador = false;
          this.modal1 = true;
        }
        return;

      }

      if(variavel === 'cpf'){
        //Valida cpf
          function validaCPF(cpf) {
              // Removendo pontos e hífen
              cpf = cpf.replace(/[^\d]/g, '');

              // Verifica se tem tamanho 11 ou se é uma sequência de dígitos repetidos
              if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
                return false;
              }

              let soma;
              let resto;
              let i;

              // Validando primeiro dígito verificador
              soma = 0;
              for (i = 1; i <= 9; i++) {
                soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
              }
              resto = (soma * 10) % 11;
              if ((resto === 10) || (resto === 11)) resto = 0;
              if (resto !== parseInt(cpf.substring(9, 10))) {
                return false;
              }

              // Validando segundo dígito verificador
              soma = 0;
              for (i = 1; i <= 10; i++) {
                soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
              }
              resto = (soma * 10) % 11;
              if ((resto === 10) || (resto === 11)) resto = 0;
              if (resto !== parseInt(cpf.substring(10, 11))) {
                return false;
              }

              return true;
            }

        let cpf =  evento.target.value


        if(!validaCPF(cpf)){
          alert("CPF é inválido, digite um CPF Válido.")
          return;
        }
        if(cpf.length !== 14) {
          alert("CPF é inválido, Utilize o cpf com o formato com pontos, XXX.XXX.XXX-XX")
          return;
        }


      }


      if(variavel ==='TelefoneDois'){
        if(evento.target.value.length >10 || evento.target.value.length <8){
          this.telefonedois_validador = false;
          this.modal1 = true;
          return;
        }
      }

      if (variavel !== 'email' && variavel !== 'EstadoCivil')
        this.informacoes_cadastrais[variavel] = evento.target.value.toUpperCase();
      else
        this.informacoes_cadastrais[variavel] = evento.target.value;


      // console.log(this.informacoes_cadastrais)
    },
    async envia_info() {
      if ((this.data_expedicao_validador !== undefined && this.telefone_validador !== undefined) || (this.data_expedicao_validador === false || this.telefone_validador === false)) {
        this.modal1 = true;
        return;
      }


      let token = localStorage.getItem('token');
      let concurso = localStorage.getItem('concurso');
      try {
        let response = await axios.post('/alteracaocadastral/info', {
          token,
          concurso,
          infos: this.informacoes_cadastrais,
          protocolo: this.protocolo_gerado,
        })

        localStorage.setItem('protocolo', this.protocolo_gerado);
        await this.$router.push('/protocoloalteracaocadastral/');
      } catch (error) {
        await this.$router.push('/login/' + concurso+'?login=alteracaocadastral');
      }
    },

  }
};
</script>
<style scoped>
.file-name {
  margin: 5px;
}
</style>
