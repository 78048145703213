/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";
import './registerServiceWorker'
import VueMask from 'v-mask';
import Axios from 'axios'
import VueViewer from 'v-viewer'

Vue.prototype.$http = Axios;

if (process.env.NODE_ENV === 'development')
    Vue.prototype.$http.defaults.baseURL = 'http://localhost:5000'
// Vue.prototype.$http.defaults.baseURL = 'http://58e472386bb0.ngrok.io'
//       Vue.prototype.$http.defaults.baseURL = 'https://dev-sistema-file-uploader.ibfc.org.br'

else
  Vue.prototype.$http.defaults.baseURL = 'https://dev-sistema-file-uploader.ibfc.org.br'



Vue.config.productionTip = false;
Vue.use(Argon);
Vue.use(VueMask);
Vue.use(VueViewer)

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
