<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img style="max-height: 70px" v-lazy="dados_concurso.logo_cliente">
                  </a>
                </div>
              </div>
              <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">22</span>-->
                  <!--                                        <span class="description">Número de inscrição</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">10</span>-->
                  <!--                                        <span class="description">Nome</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">89</span>-->
                  <!--                                        <span class="description">CPF</span>-->
                  <!--                                    </div>-->
                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ dados_concurso.nome_cliente }}
              </div>
              <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>{{ dados_concurso.nome_concurso }}
              </div>
              <!--              <h3 class="mt-3">Protocolo-->
              <!--              </h3>-->
            </div>
            <div class="mt-1 py-4 border-top text-center">
              <div class="btn_enviar row justify-content-center">
                <div class="col-lg-12">
                  <h5>Protocolo </h5>
                  <h6>Número: {{ `${reembolso}` }}</h6>
                </div>
                <div>

                  <base-button :disabled="!btnConfirmacao" @click="envia_texto" type="primary" class="my-4">
                    Imprimir Protocolo
                  </base-button>

                  <h5>Suas informações foram recebidas com sucesso!<br> Clique em FINALIZAR para sair.</h5>

                  <base-button @click="logout" type="danger" class="my-4">Finalizar</base-button>

                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../components/Modal';
import axios from "axios";
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import Vue from 'vue'


Vue.use(VueViewer)

export default {
  data: function () {
    return {
      concurso:{},
      // reembolso:{},
      dados_concurso: {},
      protocolo: "",
      file: "",
      modal1: false,
      btnConfirmacao: true,
      texto: '',
      images: []
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    Modal: Modal,
  }, beforeMount() {
    (async () => {

      //Popula dados do concurso;
      this.concurso = localStorage.getItem('concurso');
      this.reembolso =localStorage.getItem('reembolso');
      try {
        let response = await axios.get('/form/info_concurso_impugnacao', {params: {concurso:this.concurso}})
        this.dados_concurso = response.data;

      } catch (error) {
        alert(error)
         // window.location.replace('https://www.ibfc.org.br')
      }


    })();

  },
  methods: {
    logout() {
      localStorage.setItem('token', '');

      window.location.replace('https://www.ibfc.org.br')
    },
    envia_texto: async function () {
      window.print();
    }
  }
};
</script>
<style scoped>
.file-name {
  margin: 5px;
}
</style>
