<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img style="max-height: 100px" v-lazy="dados_concurso.logo_cliente">
                  </a>
                </div>
              </div>
              <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">

                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <h3>Solitação de Reembolso
              </h3>
              <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ dados_concurso.nome_cliente }}
              </div>
              <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>{{ dados_concurso.nome_concurso }}
              </div>
            </div>

            <div class="mt-5 py-3 border-top text-center">
              <h5>Lista de Inscrições</h5>
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <th class="col-md-3" scope="col">Inscrição</th>
                      <th scope="col">Cargo</th>
                      <th scope="col">Pedir Reembolso</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item) in lista_envios" :key="item.protocolo+item.tipo_arquivo">
                      <td scope="row">{{ item.num_cand }}</td>
                      <td>{{ item.cargo }}</td>
                      <td>
                        <base-button @click="visualiza(item)"
                         type="primary">
                          Fazer pedido
                        </base-button>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>

    <loading :active="isLoading"
             :can-cancel="true"
             :is-full-page="fullPage"/>
    <modal :show.sync="modal1">
      <h6 slot="header" class="modal-title" id="modal-title-default">Nome: {{ candidato_analisado.nome }} |
        Situação Atual: {{ candidato_analisado.status_solicitacao_impugnacao }} | Cargo: {{ candidato_analisado.cargo }}
      </h6>

      <div class="row">
        <h6>Impugnação</h6>
      </div>
      <div class="row  justify-content-center">
        <div class="col-md-12">
          <pre
              style="word-wrap: break-word;white-space: pre-wrap;">{{ candidato_analisado.motivo }}</pre>
        </div>
      </div>

      <div class="row justify-content-center border-top mt-4 ">
        <div class="col-md-12">
          <h5 class="text-center mt-3">Justificativa / Motivo / Observação</h5>
        </div>


        <div class="col-md-8">
          <textarea class="form-control mt-4" rows="3" v-model="candidato_analisado.justificativa"></textarea>
        </div>
      </div>
      <template slot="footer">
        <base-button type="link" class="ml-auto" @click="modal1 = false">Voltar</base-button>
        <base-button type="secondary" class="ml-auto" @click="altera_situacao('pendente')">Pendente</base-button>
        <base-button type="danger" class="ml-auto" @click="altera_situacao('indeferido')">Indeferido</base-button>
        <base-button type="success" class="ml-auto" @click="altera_situacao('deferido')">Deferido</base-button>
      </template>
    </modal>

  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../components/Modal';
import axios from "axios";
import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Vue from 'vue'
import base_dropdown from '../components/BaseDropdown'
import base_pagination from '../components/BasePagination'
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

// Vue.use(VueViewer)

export default {
  data: function () {
    return {
      justificativa: '',
      pagination: 1,
      qtd_paginas: 5,
      filtro_status_solicitacao: null,
      candidato_analisado: {},
      modal_info: {},
      dados_candidato: {},
      dados_concurso: {},
      file: "",
      modal1: false,
      modal_pdf: false,
      pdf_src: "",
      btnConfirmacao: true,
      images: [],
      lista_envios: [],
      isLoading: false,
      fullPage: true
    }
  },
  components: {
    Loading,
    vueDropzone: vue2Dropzone,
    VuePdfApp,
    Modal: Modal,
    'base-dropdown': base_dropdown,
    'base-pagination': base_pagination,

  },
  beforeMount() {
    (async () => {
      //Popula dados do concurso;
      let token = localStorage.getItem('token');
      let concurso = localStorage.getItem('concurso');
      try {
        let response = await axios.get('/form/info_concurso', {params: {token, concurso}})
        this.dados_concurso = response.data;

        response = await axios.get('/user/lista_inscricoes', {
          headers: {
            token,
          }
        });


        this.lista_envios = response.data;

      } catch (error) {
        console.log('Error ao carregar dadados ', error.message);
        if (!concurso)
          await this.$router.push('https://www.ibfc.org.br');
        await this.$router.push('/login/' + concurso);
      }

    })();

  },
  methods: {
    async visualiza(item) {
      await this.$router.push(`/reembolso?num_cand=${item.num_cand}&concurso=${item.concurso}&cpf=${item.cpf}`);
    },
  }
};
</script>
<style>
.modal-dialog {
  max-width: 80% !important;
  z-index: 1000;
}
</style>
