<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img style="max-height: 75px" v-lazy="dados_concurso.logo_cliente">
                  </a>
                </div>
              </div>
              <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">22</span>-->
                  <!--                                        <span class="description">Número de inscrição</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">10</span>-->
                  <!--                                        <span class="description">Nome</span>-->
                  <!--                                    </div>-->
                  <!--                                    <div>-->
                  <!--                                        <span class="heading">89</span>-->
                  <!--                                        <span class="description">CPF</span>-->
                  <!--                                    </div>-->
                </div>
              </div>
            </div>
            <div class="text-center mt-5">
              <h3>Envio de documentos
              </h3>
              <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ dados_concurso.nome_cliente }}
              </div>
              <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>{{ dados_concurso.nome_concurso }}
              </div>
            </div>
            <div class="mt-5 py-3 border-top text-center">
              <h5>Dados do Candidato</h5>
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <h6 class="text-left"><b>Nome: </b>{{ dados_candidato.nome }}</h6>
                  <h6 class="text-left"><b>Número de Inscrição: </b>{{ dados_candidato.num_cand }}</h6>
                  <h6 class="text-left"><b>Email: </b>{{ dados_candidato.email }}</h6>
                  <h6 class="text-left"><b>Cargo: </b>{{ dados_candidato.cargo }}</h6>
                </div>
              </div>
            </div>
            <div v-if="dados_candidato.modulos.solicitacao_especial" class="mt-1 py-4 border-top text-center">
              <h5>{{ dados_concurso.solicitacao_especial_nome }}</h5>
              <h6>{{ dados_concurso.solicitacao_especial_descricao }}</h6>
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="field">
                    <vue-dropzone ref="drop_solicitacao_especial" id="drop_solicitacao_especial"
                                  :options="dropzoneOptions_solicitacao_especial"></vue-dropzone>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="dados_candidato.modulos.pcd" class="mt-1 py-4 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="field">
                    <h5>{{ dados_concurso.pcd_nome }}</h5>
                    <h6>{{ dados_concurso.pcd_descricao }}</h6>
                    <vue-dropzone ref="drop_pcd" id="drop_pcd" :options="dropzoneOptions_pcd"></vue-dropzone>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="dados_candidato.modulos.nome_social" class="mt-1 py-4 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="field">
                    <h5>{{ dados_concurso.nome_social_nome }}</h5>
                    <h6>{{ dados_concurso.nome_social_descricao }}</h6>
                    <vue-dropzone ref="drop_nome_social" id="drop_nome_social"
                                  :options="dropzoneOptions_nome_social"></vue-dropzone>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="dados_candidato.modulos.jurado" class="mt-1 py-4 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="field">

                    <h5>{{ dados_concurso.jurado_nome }}</h5>
                    <h6>{{ dados_concurso.jurado_descricao }}</h6>
                    <vue-dropzone ref="drop_jurado" id="drop_jurado" :options="dropzoneOptions_jurado"></vue-dropzone>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="dados_candidato.Pago >= 2" class="mt-1 py-4 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <div class="field">

                    <h5>{{ dados_concurso.isencao_nome }}</h5>
                    <h6>{{ dados_concurso.isencao_descricao }}</h6>
                    <vue-dropzone ref="drop_isencao" id="drop_isencao"
                                  :options="dropzoneOptions_isencao"></vue-dropzone>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-1 py-4 border-top text-center">
              <div class="btn_enviar row justify-content-center">
                <div class="col-lg-9">
                  <div class="btnConfirmacao">
                    <base-checkbox class="mb-3" v-model="btnConfirmacao">
                      <b>Declaro que as informações anexadas acima estão corretas e são verdadeiras.</b>
                    </base-checkbox>
                  </div>
                  <base-button :disabled="!btnConfirmacao" @click="seleciona_arquivos" native-type="submit"
                               type="primary" class="my-4">
                    Protocolar
                    Arquivos
                  </base-button>
                  <!--                  <base-button native-type="submit" type="danger" class="my-4">Sair</base-button>-->
                  <modal :show.sync="modal1">
                    <h6 slot="header" class="modal-title" id="modal-title-default">Erro ao protocolar envio de
                      arquivo</h6>

                    <h5>Nenhuma imagem foi selecionada!</h5>
                    <b>É necessário clicar na área indicada para selecionar os arquivos que deseja enviar.</b>

                    <template slot="footer">
                      <base-button type="link" class="ml-auto" @click="modal1 = false">Voltar
                      </base-button>
                    </template>
                  </modal>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../components/Modal';
import axios from "axios";

export default {
  data: function () {
    return {
      origem_arquivo: 'documentos',
      dados_candidato: {
        modulos: {
          jurado: false,
          pcd: false,
          solicitacao_especial: false,
          nome_social: false,
          isencao: true
        }
      },
      dados_concurso: {},
      file: "",
      protocolo_gerado: '',
      modal1: false,
      btnConfirmacao: false,
      dropzoneOptions: {
        timeout: 100000,
        url: this.$http.defaults.baseURL + '/form/upload_files',
        thumbnailWidth: 150,
        maxFilesize: 4,
        dictRemoveFile: "Remover",
        addRemoveLinks: true,
        acceptedFiles: 'image/jpg,image/jpeg,image/png,application/pdf',
        autoProcessQueue: false,
        dictDefaultMessage: "Clique ou arraste para enviar arquivos",
        headers: {token: localStorage.getItem('token'), tipoarquivo: '', protocolo: '', origem_arquivo: 'documentos'}
      },
      dropzoneOptions_nome_social: {
        timeout: 100000,
        url: this.$http.defaults.baseURL + '/form/upload_files',
        thumbnailWidth: 150,
        maxFilesize: 4,
        dictRemoveFile: "Remover",
        addRemoveLinks: true,
        acceptedFiles: 'image/jpg,image/jpeg,image/png,application/pdf',
        autoProcessQueue: false,
        dictDefaultMessage: "Clique ou arraste para enviar arquivos",
        headers: {
          token: localStorage.getItem('token'),
          tipoarquivo: 'nome_social',
          protocolo: '',
          origemarquivo: 'documentos'
        }
      },
      dropzoneOptions_solicitacao_especial: {
        timeout: 100000,
        url: this.$http.defaults.baseURL + '/form/upload_files',
        thumbnailWidth: 150,
        maxFilesize: 4,
        dictRemoveFile: "Remover",
        addRemoveLinks: true,
        acceptedFiles: 'image/jpg,image/jpeg,image/png,application/pdf',
        autoProcessQueue: false,
        dictDefaultMessage: "Clique ou arraste para enviar arquivos",
        headers: {
          token: localStorage.getItem('token'),
          tipoarquivo: 'solicitacao_especial',
          protocolo: '',
          origemarquivo: 'documentos'
        }
      },
      dropzoneOptions_pcd: {
        timeout: 100000,
        url: this.$http.defaults.baseURL + '/form/upload_files',
        thumbnailWidth: 150,
        maxFilesize: 4,
        dictRemoveFile: "Remover",
        addRemoveLinks: true,
        acceptedFiles: 'image/jpg,image/jpeg,image/png,application/pdf',
        autoProcessQueue: false,
        dictDefaultMessage: "Clique ou arraste para enviar arquivos",
        headers: {token: localStorage.getItem('token'), tipoarquivo: 'pcd', protocolo: '', origemarquivo: 'documentos'}
      },
      dropzoneOptions_jurado: {
        timeout: 100000,
        url: this.$http.defaults.baseURL + '/form/upload_files',
        thumbnailWidth: 150,
        maxFilesize: 4,
        dictRemoveFile: "Remover",
        addRemoveLinks: true,
        acceptedFiles: 'image/jpg,image/jpeg,image/png,application/pdf',
        autoProcessQueue: false,
        dictDefaultMessage: "Clique ou arraste para enviar arquivos",
        headers: {
          token: localStorage.getItem('token'),
          tipoarquivo: 'jurado',
          protocolo: '',
          origemarquivo: 'documentos'
        }
      },
      dropzoneOptions_isencao: {
        timeout: 100000,
        url: this.$http.defaults.baseURL + '/form/upload_files',
        thumbnailWidth: 150,
        maxFilesize: 4,
        dictRemoveFile: "Remover",
        addRemoveLinks: true,
        acceptedFiles: 'image/jpg,image/jpeg,image/png,application/pdf',
        autoProcessQueue: false,
        dictDefaultMessage: "Clique ou arraste para enviar arquivos",
        headers: {
          token: localStorage.getItem('token'),
          tipoarquivo: 'isencao',
          protocolo: '',
          origemarquivo: 'documentos'
        }
      }
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    Modal: Modal
  },
  beforeMount() {
    (async () => {

      //Popula dados do concurso;
      let token = localStorage.getItem('token');
      let concurso = localStorage.getItem('concurso');


      localStorage.setItem('origem_arquivo', 'documentos');
      this.origem_arquivo = 'documentos';

      try {
        let response = await axios.get('/form/info_concurso', {params: {token, concurso}})
        this.dados_concurso = response.data;
        response = await axios.get('/form/info_candidato', {params: {token, concurso}})
        this.dados_candidato = response.data;


        // if (this.dados_candidato.concurso.indexOf('2301') < 0 && this.dados_candidato.concurso !== '2225' && this.dados_candidato.concurso !== '2228' )
        //   this.dados_candidato.Pago = 0;

 if (this.dados_candidato.concurso.indexOf('2317')>=0)
        this.dados_candidato.Pago = 0;
 //Evita que o candidato envie documentos de isencao apos o periodo
    //     console.log('Dados do candidato', this.dados_candidato.concurso,this.dados_candidato.concurso.indexOf('22042'),this.dados_candidato.Pago ,this.dados_candidato.Pago >= 2, this.dados_candidato)
    //     if (this.dados_candidato.concurso.indexOf('22042') >= 0) {
    //       let agora = new Date();
    //       let fim = new Date('2023-01-20T02:00:00Z');
    // console.log(agora,fim,agora>fim)
    //       if (agora > fim) {
    //         this.dados_candidato.Pago = 0;
    //       }
    //     }


        // if(this.dados_concurso.concurso ==='2214'){
        //   this.dados_candidato.modulos.jurado = false;
        //   this.dados_candidato.modulos.pcd = false;
        // }

        response = await axios.get('/form/info_protocolo', {params: {token, concurso}});
        this.protocolo_gerado = response.data.protocolo;


      } catch (error) {
        await this.$router.push('/login/' + concurso + '?login=documentos');
      }

    })();

  },
  methods: {
    async seleciona_arquivos() {


      let arquivos_na_fila = 0;
      arquivos_na_fila += this.$refs.drop_jurado !== undefined ? this.$refs.drop_jurado.getQueuedFiles().length : 0;
      arquivos_na_fila += this.$refs.drop_pcd !== undefined ? this.$refs.drop_pcd.getQueuedFiles().length : 0;
      arquivos_na_fila += this.$refs.drop_nome_social !== undefined ? this.$refs.drop_nome_social.getQueuedFiles().length : 0;
      arquivos_na_fila += this.$refs.drop_solicitacao_especial !== undefined ? this.$refs.drop_solicitacao_especial.getQueuedFiles().length : 0;
      arquivos_na_fila += this.$refs.drop_isencao !== undefined ? this.$refs.drop_isencao.getQueuedFiles().length : 0;

      if (arquivos_na_fila == 0) {
        this.modal1 = true
        return
      }

      this.dropzoneOptions.headers.protocolo = this.protocolo_gerado;

      if (this.$refs.drop_jurado !== undefined) {
        this.dropzoneOptions_jurado.headers.protocolo = this.protocolo_gerado;
        await this.$refs.drop_jurado.processQueue();
      }
      if (this.$refs.drop_pcd !== undefined) {
        this.dropzoneOptions_pcd.headers.protocolo = this.protocolo_gerado;
        await this.$refs.drop_pcd.processQueue();
      }
      if (this.$refs.drop_nome_social !== undefined) {
        this.dropzoneOptions_nome_social.headers.protocolo = this.protocolo_gerado;
        await this.$refs.drop_nome_social.processQueue();
      }
      if (this.$refs.drop_solicitacao_especial !== undefined) {
        this.dropzoneOptions_solicitacao_especial.headers.protocolo = this.protocolo_gerado;
        await this.$refs.drop_solicitacao_especial.processQueue();
      }
      if (this.$refs.drop_isencao !== undefined) {
        this.dropzoneOptions_isencao.headers.protocolo = this.protocolo_gerado;
        await this.$refs.drop_isencao.processQueue();
      }

      const interval = setInterval(async () => {

        let drops = [];
        if (this.$refs.drop_jurado !== undefined)
          drops.push(this.$refs.drop_jurado.dropzone.files);
        if (this.$refs.drop_pcd !== undefined)
          drops.push(this.$refs.drop_pcd.dropzone.files);
        if (this.$refs.drop_solicitacao_especial !== undefined)
          drops.push(this.$refs.drop_solicitacao_especial.dropzone.files);
        if (this.$refs.drop_nome_social !== undefined)
          drops.push(this.$refs.drop_nome_social.dropzone.files);
        if (this.$refs.drop_isencao !== undefined)
          drops.push(this.$refs.drop_isencao.dropzone.files);


        let flag = true;
        const verifica_arquivos = (drop) => {
          for (let item of drop) {
            console.log(item)
            if (item.status !== 'success') {
              flag = false;
            }
          }
        }

        for (let drop of drops) {
          verifica_arquivos(drop)
        }
        if (flag) {
          clearInterval(interval);

          return await this.$router.push('/protocolo');

        }
      }, 2000)

      // await this.$router.push('/protocolo');

    }
  }
};
</script>
<style scoped>
.file-name {
  margin: 5px;
}
</style>
