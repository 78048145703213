<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <card type="secondary" shadow
                header-classes="bg-white pb-7"
                body-classes="px-lg-7 py-lg-7"
                class="border-0">
            <template>

              <div v-if="mostra_mensagem">
                <h5 class="text-center">PRAZO ENCERRADO</h5>
                <h6 class="text-left">
                  <br>
                  Prezado candidato,<br>
                  Caso não tenha o seu número de sua inscrição em mãos, acesse o site do IBFC e na aba <strong>“Situação da Inscrição e Correção Cadastral”</strong> encontre as listas das inscrições deferidas por letra inicial dos nomes. Após acessar a lista com a inicial do seu nome é possível realizar a pesquisa no campo <strong>“Pesquisar”</strong>, localizado do lado superior direito da página.
                  Sua inscrição também pode ser encontrada no seu comprovante de inscrição ou boleto gerado ao se inscrever.
                  <br>
                </h6>

              </div>

              <div v-if="login_errado" class="alert alert-danger" role="alert">
                Número de inscrição ou CPF inválido.
              </div>
              <div class="text-muted text-center mb-3">
                <small>Entre com:</small>
              </div>
              <div class="btn-wrapper text-center">
              </div>
            </template>
            <template>
              <form @submit.prevent="envia_login" role="form">
                <base-input v-model="username" alternative
                            class="mb-3"
                            v-mask="'#######-#'"
                            placeholder="Número de inscrição"
                            addon-left-icon="ni ni-single-02">
                </base-input>
                <base-input v-mask="'###.###.###-##'" v-model="password" alternative

                            placeholder="CPF"
                            addon-left-icon="ni ni-badge">
                </base-input>

                <div class="text-center">
                  <base-button native-type="submit" type="primary" class="my-4">Entrar</base-button>
                </div>


              </form>
            </template>
          </card>
          <div class="row mt-3">
            <div class="col-6">
              <a href="#" class="text-light">
                <!--                <small>Esqueci minha senha</small>-->
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import {useRouter} from 'vue-router';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      login_errado: false,
      mostra_mensagem: false,
      edital: '',
    }
  },
  beforeMount() {
    let concurso = this.$route.params.concurso;
    // if (this.$route.query.login === 'documentos' && (concurso.indexOf('2216')) >= 0) {
    //   this.mostra_mensagem = true
    // }

  },
  methods: {
    envia_login: async function () {

      try {
        let concurso = this.$route.params.concurso;
        localStorage.setItem('concurso', concurso);
        let arquivo_origem = this.$route.query.login


        // if (concurso <= 2200) {
        //   throw new Error('concurso invalido!!!')
        // }


        // if (concurso === '2101' || concurso === '2104' || concurso === '2107' || concurso === '2002' || concurso === '2006' || concurso === '2108' || concurso === '2109' || concurso === '2106')
        //   throw new Error('concurso invalido')
        //
        //
        // if (concurso === '2110' || concurso === '2111') {
        //   throw new Error('concurso invalido');
        // }
        //
        // if (concurso === '2112' || concurso === '2113' || concurso === '2114') {
        //   throw new Error('concurso invalido');
        // }

        if (concurso === '2201' || concurso === '2201T' || concurso === '2202' || concurso === '2203' || concurso === '2204') {
          throw new Error('concurso invalido');
        }

        if (concurso.indexOf('2201') >= 0) {
          throw new Error('concurso invalido');
        }


        if (concurso.indexOf('2202') >= 0) {
          throw new Error('concurso invalido');
        }

        // if (concurso === '2204T') {
        //   throw new Error('concurso invalido');
        // }

        if (concurso === '2205') {
          if (arquivo_origem === 'ateracaocadastral') {
            let fim = new Date('2022-05-18T20:00:00Z')
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos')
            throw new Error('concurso invalido');
        }

        if (concurso === '2206') {
          if (arquivo_origem === 'ateracaocadastral') {
            let fim = new Date('2022-05-18T20:00:00Z')
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos')
            throw new Error('concurso invalido');
        }


        if (concurso === '2207' || concurso.indexOf('2207') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-06-29T20:00:00Z')
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos')
            throw new Error('concurso invalido');
        }


        if (concurso === '2208' || concurso === '2208H' || concurso.indexOf('2208') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-06-01T20:00:00Z')
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }


        if (concurso === '2209') {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-06-08T20:00:00Z')
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2210') {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-06-08T20:00:00Z')
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }


        if (concurso === '2211' || concurso === '2211TD') {
          if (arquivo_origem === 'titulos') {
            let fim = new Date('2022-10-28T20:00:00Z')
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'alteracaocadastral' || arquivo_origem === 'documentos') {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2212' || concurso.indexOf('2212') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-06-08T20:00:00Z')
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }


        if (concurso === '2214' || concurso.indexOf('2214') >= 0) {

          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-09-07T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }
        if (concurso === '2215' || concurso.indexOf('2215') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-09-28T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }


        if (concurso === '2217' || concurso.indexOf('2217') >= 0) {
          if (arquivo_origem === 'titulos') {
            let fim = new Date('2022-12-30T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'alteracaocadastral') {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2218' || concurso.indexOf('2218') >= 0) {
          if (arquivo_origem === 'titulos') {
            let fim = new Date('2022-11-11T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'alteracaocadastral') {
            throw new Error('concurso invalido!!!');
          }
        }


        if (concurso === '2219T' >= 0) {
          if (arquivo_origem === 'titulos') {
            let fim = new Date('2022-10-25T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'alteracaocadastral' || arquivo_origem === 'documentos') {
            throw new Error('concurso invalido!!!');
          }
        }


        if (concurso === '2220' || concurso.indexOf('2220') >= 0) {
          if (arquivo_origem === 'titulos') {
            let fim = new Date('2022-12-16T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!! ac');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'alteracaocadastral') {
            throw new Error('concurso invalido!!! d');
          }
        }

        if (concurso === '2221' || concurso.indexOf('2221') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-09-21T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }


        if (concurso === '2216') {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-12-07T19:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }

          if (concurso === '2216DOC' && arquivo_origem === 'documentos') {
            let fim = new Date('2022-11-27T19:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }


          if (concurso === '2216' && (arquivo_origem === 'documentos' || arquivo_origem === 'titulos')) {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2216R') {
          if (arquivo_origem === 'login_dt_cpf_lista_reembolso') {
            let fim = new Date('2022-11-25T03:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }


        if (concurso === '2222' || concurso.indexOf('2222') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-11-09T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }

        if ( concurso.indexOf('2223I') >= 0) {
          if (arquivo_origem === 'documentos') {
            let fim = new Date('2023-07-27T02:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'alteracaocadastral' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2224' || concurso.indexOf('2224') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2022-12-14T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2225' || concurso.indexOf('2225') >= 0) {
          if (arquivo_origem === 'titulos') {
            let fim = new Date('2023-03-08T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'alteracaocadastral' || arquivo_origem === 'documentos') {
            throw new Error('concurso invalido!!!');
          }
        }


        if (concurso === '2227' || concurso.indexOf('2227') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2023-02-08T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2228' || concurso.indexOf('2228') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2023-03-16T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2229' || concurso.indexOf('2229') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2023-01-25T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos'  || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2301I' >= 0 || concurso.indexOf('2301I3') >= 0) {
          if (arquivo_origem === 'documentos') {
            let fim = new Date('2023-11-07T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'alteracaocadastral'  || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2302' || concurso.indexOf('2302') >= 0) {
          if (arquivo_origem === 'alteracaocadastral') {
            let fim = new Date('2023-04-06T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'documentos'  || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }

          if (concurso === '2317' || concurso === '2317T') {
          if (arquivo_origem === 'alteracaocadastral' || arquivo_origem ==='documentos') {
            let fim = new Date('2023-12-20T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'titulos' ) {
            throw new Error('concurso invalido!!!');
          }
        }

        if (concurso === '2317SJ' ) {
          if (arquivo_origem === 'documentos' || arquivo_origem ==='titulos') {

            let fim = new Date('2025-02-07T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'alteracaocadastral') {
            throw new Error('concurso invalido!!!');
          }
        }
        // if (concurso > 2302) {
        //   throw new Error('concurso invalido');
        // }

        const response = await axios.post('/user/login', {
          username: this.username,
          password: this.password,
          concurso: concurso,
          origem_arquivo: this.$route.query.login,
        });
        let token = response.data.token;
        localStorage.setItem('token', token);

        // if (response.data.apr === true) {
        //   return await this.$router.push('/aprovador');
        // }


        if (this.$route.query.login) {
          return await this.$router.push(`/${this.$route.query.login}`);
        }
        // return await this.$router.push('/alteracaocadastral');
        //TODO Colocar um valor padrao no banco de dados e trazer junto
        return await this.$router.push('/titulos');

      } catch (error) {
        console.log(error);
        this.login_errado = true;
      }
    }
  }
};
</script>
<style>
</style>
