<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4">
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-3 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img style="max-height: 75px" v-lazy="dados_concurso.logo_cliente">
                  </a>
                </div>
              </div>
              <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
              </div>
              <div class="col-lg-4 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">
                </div>
              </div>
            </div>
            <div class="text-center mt-5 pt-3">
              <h3>Envio de Imagens e Vídeos para Heteroidentificação
              </h3>
              <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>{{ dados_concurso.nome_cliente }}
              </div>
              <div class="h6 mt-4"><i class="ni business_briefcase-24 mr-2"></i>{{ dados_concurso.nome_concurso }}
              </div>
            </div>
            <div class="mt-5 py-3 border-top text-center">
              <h5>Dados do Candidato</h5>
              <div class="row justify-content-center">
                <div class="col-lg-9">
                  <h6 class="text-left"><b>Nome: </b>{{ dados_candidato.nome }}</h6>
                  <h6 class="text-left"><b>Número de Inscrição: </b>{{ dados_candidato.num_cand }}</h6>
                  <h6 class="text-left"><b>Email: </b>{{ dados_candidato.email }}</h6>
                  <h6 class="text-left"><b>Cargo: </b>{{ dados_candidato.cargo }}</h6>
                </div>
              </div>
            </div>
            <div class="mt-1 py-4 border-top text-center">
              <div class="row justify-content-center">
                <div class="col-md-12">
                  <h5 style="background-color: #052c3c;color: white">Envio de Imagens e Vídeos para Heteroidentificação</h5>
                  <h4 class="pt-3">Instruções</h4>
                </div>

                <div class="row my-4 justify-content-center ">
                  <div style="white-space:normal" class="ml-0 pl-0 col-md-12 text-left">
                    <ul>Anexar a imagem colorida do documento de identidade (frente e verso);</ul>
                    <ul> Anexar 1 (uma) foto colorida de frente (com o fundo branco);</ul>
                    <ul> Anexar 1 (uma) foto colorida de perfil (com o fundo branco);</ul>
                    <ul> Anexar 1 (um) vídeo de no máximo 20 (vinte) segundos; o candidato deverá dizer o seu nome, o
                      cargo a que
                      concorre e os seguintes dizeres: “declaro que sou negro, da cor preta ou parda”
                    </ul>
                  </div>

                  <div class="row my-4 justify-content-center ">
                    <div style="white-space:normal" class=" col-md-10 text-left">
                      <h5>Padrões para Fotos e Vídeo</h5>
                      <h6>As fotos que serão enviadas ao IBFC devem seguir o mesmo padrão das fotos de documentos
                        oficiais,
                        dessa forma, é necessário que algumas recomendações sejam seguidas:</h6>
                      <ul>a) que o fundo da foto seja em um fundo branco;</ul>
                      <ul>b) que o candidato esteja com a postura correta com a coluna bem alinhada;</ul>
                      <ul>c) não esteja de cabeça baixa, nem de cabeça erguida;</ul>
                      <ul>d) que não esteja usando óculos, boné, touca e que não esteja sorrindo.</ul>
                      <ul>e) no caso de candidatos com cabelo comprido, a foto do perfil esquerdo deve estar com o
                        cabelo atrás da orelha.
                      </ul>
                    </div>
                    <div class="col-md-10">
                      <img src="https://fs.ibfc.org.br/arquivos/exemplo_hetero.jpg" alt="">
                    </div>
                  </div>
                </div>

              </div>


              <div class="py-2 row justify-content-center">
                <div class="col-lg-9">
                  <div class="field">
                    <h6 class="text-justify"><b>Instruções:</b> Arraste e solte no quadro abaixo, ou clique no quadro
                      para
                      selecionar as imagens desejadas. Serão aceitos somente arquivos .mov, .mp4, .jpg, .jpeg ou .png
                    </h6>
                    <vue-dropzone v-on:vdropzone-sending="fileAdded('heteroidentificacao',...arguments)"
                                  :ref="`drop_heteroidentificacao`"
                                  :id="`drop_heteroidentificacao`"
                                  :options="dropzoneOptions"></vue-dropzone>
                  </div>
                </div>
              </div>
            </div>


            <div class="mt-1 py-4 border-top text-center">
              <div class="btn_enviar row justify-content-center">
                <div class="col-lg-9">
                  <div class="btnConfirmacao">
                    <base-checkbox class="mb-3" v-model="btnConfirmacao">
                      <b>Declaro que as informações acima estão corretas e são verdadeiras.</b>
                    </base-checkbox>
                  </div>
                  <base-button :disabled="!btnConfirmacao" @click="envia_arquivos" native-type="submit"
                               type="primary" class="my-4">
                    Enviar
                    Arquivos
                  </base-button>
                  <!--                  <base-button native-type="submit"  type="danger" class="my-4">Sair</base-button>-->
                  <modal :show.sync="modal1">
                    <h6 slot="header" class="modal-title" id="modal-title-default">Erro ao protocolar envio de
                      arquivo</h6>

                    <h5 v-if="erro_escolha">É necessário escolher uma pontuação!</h5>
                    <b v-if="erro_escolha">É Clique em "Clique para Escolher" para escolher uma pontuação </b>

                    <br>

                    <h5 v-if="erro_sem_arquivos">Nenhuma imagem foi selecionada!</h5>
                    <b v-if="erro_sem_arquivos">É necessário clicar na área indicada para selecionar os arquivos que
                      deseja enviar.</b>

                    <div v-if="erros_validacao.length>0">
                      <h4>Erros encontrados</h4>
                      <div v-for="erro in erros_validacao" :key="erro"><br>{{ erro }}</div>

                      <br>
                      <h5>Corrija os erros acima e clique em <b>Enviar Arquivos</b> para tentar novamente </h5>

                    </div>


                    <template slot="footer">
                      <base-button type="link" class="ml-auto" @click="modal1 = false">Voltar
                      </base-button>
                    </template>
                  </modal>

                  <loading :active="isLoading" :can-cancel="false" :is-full-page="true"/>


                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../components/Modal';
import axios from "axios";
import BaseDropdown from "@/components/BaseDropdown";
import ExpProInput from "@/components/ExpProInput";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  data: function () {
    return {
      sejuf_area: {},
      sejuf_area_escolhida: {},

      isLoading: false,
      dropzoneOptions_array: [],
      registros: {},
      pontuacao_escolhida: {},
      estrutura_titulos: {},
      dados_candidato: {},
      dados_concurso: {},
      file: "",
      protocolo_gerado: '',
      modal1: false,
      btnConfirmacao: false,
      erro_sem_arquivos: false,
      erros_validacao: [],
      erro_escolha: false,
      dropzoneOptions: {
        timeout: 100000000000,
        url: this.$http.defaults.baseURL + '/titulos/upload_files',
        thumbnailWidth: 150,
        maxFilesize: 52.0,
        dictRemoveFile: "Remover",
        addRemoveLinks: true,
        acceptedFiles: 'video/mp4,video/quicktime,image/jpg,image/jpeg,image/png,application/pdf',
        autoProcessQueue: false,
        dictDefaultMessage: "Clique ou arraste para enviar arquivos",
        headers: {
          token: localStorage.getItem('token'),
          tipoarquivo: 'heteroidentificacao',
          protocolo: '',
          origemarquivo: 'heteroidentificacao'
        },
        // params: {extra:{}}
      }

    }
  },
  components: {
    vueDropzone: vue2Dropzone,
    Modal: Modal,
    BaseDropdown: BaseDropdown,
    ExpProInput: ExpProInput,
    Loading,
  },
  beforeMount() {
    (async () => {

      //Popula dados do concurso;
      let token = localStorage.getItem('token');
      let concurso = localStorage.getItem('concurso');
      localStorage.setItem('origem_arquivo', 'titulos');

      try {
        let response = await axios.get('/form/info_concurso', {params: {token, concurso}})
        this.dados_concurso = response.data;

        response = await axios.get('/form/info_candidato', {params: {token, concurso}})
        this.dados_candidato = response.data;

        response = await axios.get('/form/info_protocolo', {params: {token, concurso}});
        this.protocolo_gerado = response.data.protocolo;
        localStorage.setItem('protocolo', this.protocolo_gerado);

        // response = await axios.get('/titulos', {params: {token, concurso}})
        // this.estrutura_titulos = response.data.titulos;

        // for (let item of this.estrutura_titulos.estrutura) {
        //   this.dropzoneOptions_array.push({id: item.id, dropzoneOptions: this.dropzoneOptions});
        // }
        // this.dropzoneOptions_array.push({id: 'heteroidentificacao', dropzoneOptions: this.dropzoneOptions});
      } catch (error) {
        await this.$router.push('/login/' + concurso + '?login=heteroidentificacao');
      }

    })();

  },
  methods: {
    valida_campos_antes_envio() {
      //Verifica se existem titulos sem pontuacao ou sem arquivos

      let erro = []
      for (let titulo of this.estrutura_titulos.estrutura) {

        if ((this.pontuacao_escolhida[titulo.id] === undefined)
            && this.$refs[`drop_titulo_${titulo.id}`][0].getQueuedFiles().length > 0) {
          // alert(`${titulo.id} Nao pontuado e com arquivo tipo 1`);
          erro.push(`Falta escolher pontuação em ${titulo.nome}`)
        }

        // if ((this.pontuacao_escolhida[titulo.id] !== undefined && this.pontuacao_escolhida[titulo.id].pontos == 0)
        //     && this.$refs[`drop_titulo_${titulo.id}`][0].getQueuedFiles().length > 0)
        //   alert(`${titulo.id} Nao pontuado e com arquivo tipo 2`)

        if ((this.pontuacao_escolhida[titulo.id] !== undefined && this.pontuacao_escolhida[titulo.id].pontos != 0)
            && this.$refs[`drop_titulo_${titulo.id}`][0].getQueuedFiles().length === 0) {
          // alert(`${titulo.id} pontuado e sem arquivo`)
          erro.push(`Falta anexar arquivos em ${titulo.nome}`)
        }
      }

      return erro;
    },

    async envia_arquivos() {
      const sleep = async (time) => {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            resolve()
          }, time)
        })
      }

      //chama validacao
      let erros_validacao = []//this.valida_campos_antes_envio();
      if (erros_validacao.length > 0) {
        this.erros_validacao = erros_validacao;
        this.modal1 = true;
        return;
      }


      let arquivos_na_fila = 0;
      arquivos_na_fila += this.$refs[`drop_heteroidentificacao`].getQueuedFiles().length;

      if (arquivos_na_fila == 0) {
        this.modal1 = true
        this.erro_sem_arquivos = true
        return
      }
      this.erro_sem_arquivos = false;


      //Atualiza para cada tipo de titulo o protocolo e o cargo
      this.dropzoneOptions.headers.protocolo = this.protocolo_gerado;
      this.dropzoneOptions.headers.idcargo = '000';


      this.isLoading = true;

      this.dropzoneOptions.headers.tipoarquivo = 'heteroidentificacao'
      this.dropzoneOptions.headers.pontuacaopedida = 0

      //Envia o arquivo
      await this.$refs[`drop_heteroidentificacao`].processQueue();
      const interval = setInterval(async () => {

        let drops = [];
        // this.dropzoneOptions.headers.tipoarquivo = titulo.id;
        drops.push(this.$refs[`drop_heteroidentificacao`].dropzone.files);

        let flag = true;
        const verifica_arquivos = (drop) => {
          for (let item of drop) {
            console.log(item)
            if (item.status !== 'success') {
              flag = false;
            }
          }
        }

        for (let drop of drops) {
          verifica_arquivos(drop)
        }
        if (flag) {
          clearInterval(interval);

          return await this.$router.push('/protocoloheteroidentificacao');

        }
      }, 2000)

      // await this.$router.push('/protocolo');

    }
    ,
    atualiza_registros(titulo_id, registro) {
      this.registros[titulo_id] = registro

    }
    ,
    fileAdded(titulo_id, file, xhr, formData) {
      console.log(`enviando info nova`, titulo_id, file, xhr, formData);
      formData.append('extra', JSON.stringify({
        registros: "heteroidentificacao",
        // extra: this.sejuf_area_escolhida[titulo_id]
      }));
      formData.append('pontuacao_pedida', 0);
      formData.append('tipoarquivo', titulo_id);
    }

  }
  ,

}
;
</script>
<style scoped>
.file-name {
  margin: 5px;
}

/*.profile-page {*/
/*  height: 5000px;*/
/*}*/

</style>
