<template>
  <div v-if="tipo_arquivo.indexOf('exp_pro')>=0">
  <h6>Atenção</h6>
    <p style="text-align-left;">Informe abaixo, de forma individual, as experiências
      nas diferentes empresas/orgãos para a pontuação, conforme os documentos que serão anexados.
<br>


    <div v-for="registro in registros" :key="registro.id" class="row">

      <div  class="col-md-3 p-1">
        Empresa/Orgão
        <base-input @change="update_registro(registro.id,$event,'empresa')" :value="registro.empresa"
                    placeholder="Empresa"></base-input>
      </div>
      <div class="col-md-3 p-1">
        Cargo
        <base-input @change="update_registro(registro.id,$event,'cargo')" :value="registro.cargo"
                    placeholder="Cargo"></base-input>
      </div>
      <div class="col-md-2 p-1">
        Data início:
        <base-input  v-mask="'##/##/####'" @change="update_registro(registro.id,$event,'inicio')" v-model="registro.inicio"
                    placeholder="Data de início"></base-input>
      </div>
      <div class="col-md-2 p-1">
        Data Fim:
        <base-input   v-mask="'##/##/####'" @change="update_registro(registro.id,$event,'fim')" v-model="registro.fim"
                    placeholder="Data de Fim"></base-input>
      </div>
      <div class="col-md-1 mt-2 ml-0 p-0">
        <i @click="remove_registro(registro.id)" style="font-size: 3em;" class="mt-3 ni ni-fat-remove"></i>
      </div>
    </div>

    <base-button @click="add_novo_registro()" type="info">Adicionar Novo Registro</base-button>
  </div>
</template>

<script>
export default {
  name: "ExpProInput",
  props: ['tipo_arquivo'],
  data: function () {
    return {
      registros_count: 1,
      registros: [{id: 1, empresa: '', cargo: '', inicio: '', fim: ''}]
    }
  },
  methods: {
    add_novo_registro() {
      this.registros_count++
      this.registros.push({id: this.registros_count, empresa: '', cargo: '', inicio: '', fim: ''})
    },
    remove_registro(id) {
      this.registros = this.registros.filter(item => item.id !== id)
    },
    update_registro(id, evento, variavel) {
      for (let [index, registro] of this.registros.entries()) {
        if (registro.id === id) {
          // this.$set(this.registros[index], variavel, evento.target.value);
          this.registros[index][variavel] = evento.target.value
        }
      }
      this.$emit('exp_pro_atualizacao', this.registros);
    }
  }
}
</script>

<style scoped>

</style>
