<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <card type="secondary" shadow
                header-classes="bg-white pb-5"
                body-classes="px-lg-5 py-lg-5"
                class="border-0">
            <template>
              <div v-if="login_errado" class="alert alert-danger" role="alert">
                Email ou Senha Inválidos!
              </div>
              <div class="text-muted text-center mb-3">
                <small>Entre com:</small>
              </div>
              <div class="btn-wrapper text-center">
              </div>
            </template>
            <template>
              <form @submit.prevent="envia_login" role="form">
                <base-input v-model="username" alternative
                            class="mb-3"
                            placeholder="Email"
                            addon-left-icon="ni ni-single-02">
                </base-input>
                <base-input  v-model="password" alternative
                            type="password"
                            placeholder="Senha"
                            addon-left-icon="ni ni-badge">
                </base-input>

                <div class="text-center">
                  <base-button native-type="submit" type="primary" class="my-4">Entrar</base-button>
                </div>
              </form>
            </template>
          </card>
          <div class="row mt-3">
            <div class="col-6">
              <a href="#" class="text-light">
                <!--                <small>Esqueci minha senha</small>-->
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import {useRouter} from 'vue-router';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      login_errado:false,
    }
  },
  methods: {
    envia_login: async function () {

      try {
        let concurso = this.$route.params.concurso;
        localStorage.setItem('concurso', concurso);

        const response = await axios.post('/user/login', {
          username: this.username,
          password: this.password,
          concurso: concurso,
        });
        let token = response.data.token;
        localStorage.setItem('token', token);

        // if (response.data.apr === true) {
        //   return await this.$router.push('/aprovadortitulos');
        // }

        if (this.$route.query.login) {
          return await this.$router.push(`/${this.$route.query.login}`);
        }
        return await this.$router.push(`/loginavaliador/${concurso}`);

      } catch (error) {
        console.log(error);
        this.login_errado=true;
      }
    }
  }
};
</script>
<style>
</style>
