<template>
  <section class="section section-shaped section-lg my-0">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <card type="secondary" shadow
                header-classes="bg-white pb-7"
                body-classes="px-lg-7 py-lg-7"
                class="border-0">
            <template>

              <div v-if="mostra_mensagem">
                <h5 class="text-center">Link para envio da documentação (Upload) para candidatos que indicaram no
                  formulário de inscrição as condições de:</h5>
                <h6 class="text-left">
                  <br>
                  ✓ Isenção da Taxa de Inscrição (Desempregado)<br>
                  ✓ Concorrer para as vagas de Pessoas com Deficiência – PcD<br>
                  ✓ Condição especial para realização da Prova<br>
                  ✓ Função de Jurado<br>
                  ✓ Nome Social - Travesti ou Transexual<br>
                </h6>

              </div>

              <div v-if="login_errado" class="alert alert-danger" role="alert">
                Data de Nascimento ou CPF inválido.
              </div>
              <div class="text-muted text-center mb-3">
                <small>Entre com:</small>
              </div>
              <div class="btn-wrapper text-center">
              </div>
            </template>
            <template>
              <form @submit.prevent="envia_login" role="form">
                <base-input v-model="username" alternative
                            class="mb-3"
                            v-mask="'##/##/####'"
                            placeholder="Data de Nascimento"
                            addon-left-icon="ni ni-single-02">
                </base-input>
                <base-input v-mask="'###.###.###-##'" v-model="password" alternative

                            placeholder="CPF"
                            addon-left-icon="ni ni-badge">
                </base-input>

                <div class="text-center">
                  <base-button native-type="submit" type="primary" class="my-4">Entrar</base-button>
                </div>


              </form>
            </template>
          </card>
          <div class="row mt-3">
            <div class="col-6">
              <a href="#" class="text-light">
                <!--                <small>Esqueci minha senha</small>-->
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios';
import {useRouter} from 'vue-router';

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      login_errado: false,
      mostra_mensagem: false,
      edital: '',
    }
  },
  beforeMount() {
    let concurso = this.$route.params.concurso;
    // if (this.$route.query.login === 'documentos' && (concurso.indexOf('2216')) >= 0) {
    //   this.mostra_mensagem = true
    // }

  },
  methods: {
    envia_login: async function () {

      try {
        let concurso = this.$route.params.concurso;
        localStorage.setItem('concurso', concurso);
        let arquivo_origem = this.$route.query.login




        if (concurso === '2225' || concurso.indexOf('2225') >= 0) {
          if (arquivo_origem === 'documentos') {
            let fim = new Date('2022-11-07T20:00:00Z');
            let agora = new Date();
            if (agora > fim)
              throw new Error('concurso invalido!!');
          }
          if (arquivo_origem === 'alteracaocadastral' || arquivo_origem === 'titulos') {
            throw new Error('concurso invalido!!!');
          }
        }


        if (concurso > 2225) {
          throw new Error('concurso invalido>');

        }

        let data = this.username.split('/');
        data = `${data[2]}-${data[1]}-${data[0]}`
        const response = await axios.post('/user/login_dt_cpf', {
          username: data,
          password: this.password,
          concurso: concurso,
          origem_arquivo: this.$route.query.login,
        });
        let token = response.data.token;
        localStorage.setItem('token', token);

        // if (response.data.apr === true) {
        //   return await this.$router.push('/aprovador');
        // }


        if (this.$route.query.login) {
          return await this.$router.push(`/${this.$route.query.login}`);
        }
        // return await this.$router.push('/alteracaocadastral');
        //TODO Colocar um valor padrao no banco de dados e trazer junto
        // return await this.$router.push('/titulos');

      } catch (error) {
        console.log(error);
        this.login_errado = true;
      }
    }
  }
};
</script>
<style>
</style>
