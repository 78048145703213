import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";
import Components from "./views/Components.vue";
import Landing from "./views/Landing.vue";
import Login from "./views/Login.vue";
import Register from "./views/Register.vue";
import Documentos from "./views/Interno.vue";
import Impugnacao from "./views/Impugnacao.vue";
import Protocolo from "./views/Protocolo.vue";
import Aprovador from "./views/Aprovador.vue";
import AlteracaoCadastral from "./views/AlteracaoCadastral.vue";
import Isencao from "./views/Isencao.vue";
import ProtocoloAlteracaoCadastral from "./views/ProtocoloAlteracaoCadastral.vue";
import Titulos from "./views/Titulos.vue";
import ProtocoloTitulos from "./views/ProtocoloTitulos2";
import LoginAvaliador from "@/views/LoginAvaliador";
import AprovadorTitulos from "@/views/AprovadorTitulos";
import PDFThumbnail from "@/views/PDFThumbnail";
import AprovadorTitulosInterno from "@/views/AprovadorTitulosInterno";
import ProtocoloImpugnacao from "@/views/ProtocoloImpugnacao";
import PGM_Inscricao from "@/views/PGM_Inscricao";
import PGM_Protocolo from "@/views/PGM_Protocolo";
import AprovadorImpugnacao from "./views/AprovadorImpugnacao.vue";
import Heteroidentificacao from "@/views/Heteroidentificacao";
import ProtocoloHeteroidentificacao from "@/views/ProtocoloHeteroidentificacao";
import reembolso from "@/views/reembolso";
import Login_cpf_e_nascimento from "@/views/Login_cpf_e_nascimento";
import Login_dt_cpf_lista_inscricoes from "@/views/Login_dt_cpf_lista_inscricoes";
import ProtocoloReembolso from "@/views/ProtocoloReembolso";

Vue.use(Router);

let router = new Router({
    mode: 'history',
    linkExactActiveClass: "active",
    routes: [
        {
            path: "/login/:concurso",
            name: "login",
            components: {
                header: AppHeader,
                default: Login,
                footer: AppFooter
            }
        },
        {
            path: "/login_dt_cpf/:concurso",
            name: "login_dt_cpf",
            components: {
                header: AppHeader,
                default: Login_cpf_e_nascimento,
                footer: AppFooter
            }
        },
        {
            path: "/login_dt_cpf_lista_reembolso",
            name: "login_dt_cpf_lista_reembolso",
            components: {
                header: AppHeader,
                default: Login_dt_cpf_lista_inscricoes,
                footer: AppFooter
            }
        },
        {
            path: "/documentos",
            name: "documentos",
            components: {
                header: AppHeader,
                default: Documentos,
                footer: AppFooter
            }, meta: {
                requiresAuth: true,
            },
        }, {
            path: "/protocolo",
            name: "protocolo",
            components: {
                header: AppHeader,
                default: Protocolo,
                footer: AppFooter
            }, meta: {
                requiresAuth: true,
            }
        },
        {
            path: "/aprovador",
            name: "aprovador",
            components: {
                header: AppHeader,
                default: Aprovador,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: "/aprovadorimpugnacao",
            name: "aprovadorimpugnacao",
            components: {
                header: AppHeader,
                default: AprovadorImpugnacao,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: "/alteracaocadastral",
            name: "alteracaocadastral",
            components: {
                header: AppHeader,
                default: AlteracaoCadastral,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: "/isencao",
            name: "isencao",
            components: {
                header: AppHeader,
                default: Isencao,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: "/protocoloalteracaocadastral",
            name: "protocoloalteracaocadastral",
            components: {
                header: AppHeader,
                default: ProtocoloAlteracaoCadastral,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: "/titulos",
            name: "titulos",
            components: {
                header: AppHeader,
                default: Titulos,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: "/heteroidentificacao",
            name: "heteroidentificacao",
            components: {
                header: AppHeader,
                default: Heteroidentificacao,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: "/protocoloheteroidentificacao",
            name: "protocoloheteroidentificacao",
            components: {
                header: AppHeader,
                default: ProtocoloHeteroidentificacao,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        },
                {
            path: "/protocoloreembolso",
            name: "protocoloreembolso",
            components: {
                header: AppHeader,
                default: ProtocoloReembolso,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: "/protocolotitulos",
            name: "protocolotitulos",
            components: {
                header: AppHeader,
                default: ProtocoloTitulos,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        },

        {
            path: "/loginavaliador/:concurso",
            name: "loginavaliador",
            components: {
                header: AppHeader,
                default: LoginAvaliador,
                footer: AppFooter,
            },
        },
        {
            path: "/aprovadortitulos",
            name: "aprovadortitulos",
            components: {
                header: AppHeader,
                default: AprovadorTitulos,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        }
        ,
        {
            path: "/aprovadortitulos/interno",
            name: "aprovadortitulosinterno",
            components: {
                header: AppHeader,
                default: AprovadorTitulosInterno,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        }
        ,
        {
            path: "/isencao",
            name: "isencao",
            components: {
                header: AppHeader,
                default: Isencao,
                footer: AppFooter,
            },
            meta: {
                requiresAuth: true,
            }
        }
        ,
        {
            path: "/impugnacao",
            name: "impugnacao",
            components: {
                header: AppHeader,
                default: Impugnacao,
                footer: AppFooter,
            },
        }
        ,
        {
            path: "/protocoloimpugnacao",
            name: "protocoloimpugnacao",
            components: {
                header: AppHeader,
                default: ProtocoloImpugnacao,
                footer: AppFooter,
            },
        },
        {
            path: "/reembolso",
            name: "reembolso",
            components: {
                header: AppHeader,
                default: reembolso,
                footer: AppFooter,
            },
        },
        {
            path: "/pgmprotocolo",
            name: "pgmprotocolo",
            components: {
                header: AppHeader,
                default: PGM_Protocolo,
                footer: AppFooter,
            },
        }
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    }
});


router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (localStorage.getItem("token") == null) {
            next({
                path: `/login/${localStorage.getItem('concurso')}`,
                // path: `/login`,
                params: {nextUrl: to.fullPath},
            });
        } else {
            next();
        }
    } else {
        next();
    }
});


export default router;
